import React from 'react';
import { BlockProps } from '../index.types';
import PageSection from 'components/page/page-section';
import PostContent from '../PostContent/PostContent';

type PageSectionType = {
    block: BlockProps['block'];
    isSecondary?: 'true' | 'false';
};

export default function PageSectionBlock({ block, ...props }: PageSectionType) {
    // @TODO: should we divide Post content and mapping of blocks?
    return block.innerBlocks ? (
        <PageSection
            theme={PageSection.Theme.NONE}
            isSecondary={props.isSecondary === 'true'}
            hasPadding={true}
            hasLimitedWidth={true}
        >
            <PostContent blocks={block.innerBlocks} />
        </PageSection>
    ) : null;
}
