import * as React from 'react';
import cx from 'classnames';

export enum CtaBoxSubTitleAlign {
    CENTERED = 'CENTERED',
    NONE = 'NONE',
}

const mapAlignToClasses = {
    [CtaBoxSubTitleAlign.CENTERED]: 'cta-box__subtitle--centered',
    [CtaBoxSubTitleAlign.NONE]: '',
};

type CtaBoxSubtitleProps = {
    align?: CtaBoxSubTitleAlign;
    marginTop?: React.CSSProperties['marginTop'];
    children?: React.ReactChild | React.ReactChild[];
};

const CtaBoxSubtitle: React.FC<CtaBoxSubtitleProps> = ({
    align = CtaBoxSubTitleAlign.NONE,
    children,
}) => {
    const classname = cx({
        'cta-box__subtitle': true,
        [mapAlignToClasses[align]]: true,
    });
    return <div className={classname}>{children}</div>;
};

export default CtaBoxSubtitle;
