import * as React from 'react';
import cx from 'classnames';

type Props = {
    logo?: React.ReactNode;
    isActive?: boolean;
    closeAction?: React.ReactNode;
    menuList?: React.ReactNode[];
    downloadAction?: React.ReactNode;
    jsClass?: string;
    jsBackDropClass?: string;
    closePanel?: (...args: any[]) => any;
};

/**
 * Make screen width < 1024 px in order to see the component
 */
function MobilePanel(props: Props) {
    const {
        logo,
        isActive,
        closeAction,
        menuList,
        downloadAction,
        closePanel,
        jsClass = 'js-mobile-navigation',
    } = props;

    const className = {
        block: cx({
            'mobile-panel': true,
            'is-active': isActive,
            [jsClass]: jsClass,
        }),
        backdrop: cx({
            'mobile-panel__backdrop': true,
        }),
    };

    return (
        <div className={className.block}>
            <div className="mobile-panel__inner">
                <div className="mobile-panel__header">
                    {logo ? <div className="mobile-panel__logo">{logo}</div> : null}
                    <div className="mobile-panel__close">{closeAction}</div>
                </div>

                {menuList ? (
                    <div className="mobile-panel__content">
                        {menuList.map((menu, index) => (
                            <div
                                className="mobile-panel__content-menu"
                                key={`mobile-panel-content-menu-${index}`}
                            >
                                {menu}
                            </div>
                        ))}
                    </div>
                ) : null}

                <div className="mobile-panel__download-app">{downloadAction}</div>

                <div className="mobile-panel__socials" id="footer">
                    <div className="flex social-icons md:h-center">
                        <a href="https://www.tiktok.com/@bumble" target="_blank" rel="noreferrer">
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/tiktok.svg?v=1"
                                alt="TikTok"
                            />
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/tiktok_hover.svg?v=1"
                                alt="TikTok"
                            />
                        </a>
                        <a href="https://twitter.com/bumble" target="_blank" rel="noreferrer">
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/twitter.svg?v=1"
                                alt="Twitter"
                            />
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/twitter_hover.svg?v=1"
                                alt="Twitter"
                            />
                        </a>
                        <a href="https://www.instagram.com/bumble" target="_blank" rel="noreferrer">
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/instagram.svg?v=1"
                                alt="Instagram"
                            />
                            <img
                                src="/bff/friendship/icons/instagram_hover.svg?v=1"
                                alt="Instagram"
                            />
                        </a>
                        <a
                            href="https://www.facebook.com/bumbleapp"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/facebook.svg?v=1"
                                alt="Facebook"
                            />
                            <img
                                src="/bff/friendship/icons/facebook_hover.svg?v=1"
                                alt="Facebook"
                            />
                        </a>
                        <a href="https://www.youtube.com/c/bumble" target="_blank" rel="noreferrer">
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/youtube.svg?v=1"
                                alt="Youtube"
                            />
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/youtube_hover.svg?v=1"
                                alt="Youtube"
                            />
                        </a>
                    </div>
                </div>
            </div>

            <div className={className.backdrop} onClick={closePanel}></div>
        </div>
    );
}

export default MobilePanel;
