import * as React from 'react';
import { BlockProps } from '../index.types';
import Text, { TextType, TextTagName } from 'components/typography/text';

export const mapLevelToHeadingType: Record<number, TextType> = {
    1: TextType.H1,
    2: TextType.H2,
    3: TextType.H3,
    4: TextType.H4,
};

export const mapLevelToTextTagName: Record<number, TextTagName> = {
    1: TextTagName.h1,
    2: TextTagName.h2,
    3: TextTagName.h3,
    4: TextTagName.h4,
};

const DEFAULT_HEADING_STYLE = TextType.H2;

export function Heading({ block: { innerHTML }, level }: BlockProps) {
    let tagName;

    if (level) {
        tagName = mapLevelToTextTagName[level];
    } else {
        /**
         * default level for WP
         */
        tagName = mapLevelToTextTagName[2];
    }

    return innerHTML ? (
        <Text
            type={(level && mapLevelToHeadingType[level]) || DEFAULT_HEADING_STYLE}
            isResponsive={true}
            html={innerHTML}
            tagname={tagName}
        />
    ) : null;
}
