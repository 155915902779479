import * as React from 'react';
import { HeroBlockContext } from 'components/success-stories/hero-block/hero-block.context';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';

export const HeroBlockClipPath = () => {
    const { opticCompensation } = React.useContext(HeroBlockContext);
    const isBreakpoint = useMediaQuery(MediaQueryWidth['tablet-md']);
    const scaleFactor = `scale(${opticCompensation[0]}, ${opticCompensation[1]}) ${
        isBreakpoint ? 'scaleX(2) translateX(-25%)' : ''
    }`;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="0"
            height="0"
            style={{
                display: 'block',
                position: 'fixed',
                top: 0,
                left: 0,
                opacity: 0,
                // @TODO: figure out why this setting "destroys" the clip-path
                // visibility: 'hidden'
            }}
            viewBox="0 0 1320 742"
        >
            {/*  @TODO: figure out why clip-path should be within svg only */}
            <clipPath
                id="bff-frame-path"
                clipPathUnits="userSpaceOnUse"
                style={{ transform: scaleFactor }}
            >
                <path d="M363.2 99.9c53.8 0 75.9-9.5 95.2-17.8l6.7-2.8a99.5 99.5 0 0 1 47-9.3c16.5 0 30.9 3.4 43.2 9.3l6.7 2.8c19.3 8.3 41.4 17.8 95.2 17.8 53.9 0 76-9.5 95.3-17.8 6.2-2.6 12-5.1 18.6-7.2a103.3 103.3 0 0 1 35-4.9c11.9 0 22.6 1.8 32.3 5 6.5 2 12.4 4.5 18.6 7.1 19.3 8.3 41.4 17.8 95.2 17.8 53.9 0 76-9.5 95.3-17.8 15-6.4 28.3-12.1 53.7-12.1 65.4 0 97.8 54.3 97.8 97.8 0 22.4-8 38.4-15.9 54-7.4 14.7-14.6 29.1-14.6 48.2 0 15.6 6.4 28.3 13.3 42 8.3 16.3 17.2 34 17.2 59.5s-9 43.2-17.2 59.5c-7 13.7-13.3 26.4-13.3 42 0 19 7.2 33.5 14.6 48.3 7.9 15.5 16 31.5 16 53.9 0 43.5-32.5 97.8-98 97.8-25.3 0-38.6-5.7-53.6-12.1-19.3-8.3-41.4-17.8-95.2-17.8-53.9 0-76 9.5-95.3 17.8-6.2 2.6-12 5.1-18.7 7.2a103.3 103.3 0 0 1-35 4.9c-11.7 0-22.4-1.7-32-4.9-6.6-2-12.6-4.6-18.8-7.2-19.3-8.3-41.4-17.8-95.2-17.8-53.9 0-76 9.5-95.3 17.8l-6.7 2.8a99.5 99.5 0 0 1-47 9.3 99.5 99.5 0 0 1-43.1-9.3l-6.8-2.8C439 652.6 417 643 363.2 643c-53.9 0-76 9.5-95.3 17.8-15 6.4-28.3 12.1-53.7 12.1-65.4 0-97.8-54.3-97.8-97.8 0-22.4 8-38.4 15.9-54 7.4-14.7 14.6-29.2 14.6-48.3 0-15.5-6.4-28.2-13.3-42-8.3-16.2-17.2-33.9-17.2-59.4s9-43.2 17.2-59.5c7-13.7 13.3-26.4 13.3-42 0-19-7.2-33.5-14.6-48.3-7.9-15.5-16-31.5-16-53.9 0-43.5 32.5-97.8 98-97.8 25.3 0 38.6 5.7 53.6 12.1 19.3 8.3 41.4 17.8 95.2 17.8Z" />
            </clipPath>
        </svg>
    );
};
