import * as React from 'react';
import Icon from 'components/icon/icon';
import { PageSEOPropsType } from 'components/page-seo/page-seo';
import { Maybe, RootQueryToCategoryConnection, Scalars } from 'interfaces/microsite-cms';
import HeroComponent from 'components/success-stories/hero-block/hero-block';
import { StructuredDataElementPage } from 'components/structured-data';
import { PageIndexCategoryList } from 'components/page-index-category-list/page-index-category-list';
import { PageArchiveCategoryList } from 'components/page-archive-category-list/page-archive-category-list';
import SocialLinks from 'components/social-links/social-links';
import PageSection from 'components/page/page-section';
import { Header2, P1 } from 'components/typography';
import Button from 'components/button/button';
import { TextTagName } from 'components/typography/text';
import { clickEventFromElement } from 'components/google-analytics/google-analytics';
import { downloadUrl } from 'lib/constants';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';
import Breadcrumbs, { setBreadCrumbs } from 'components/breadcrumbs/breadcrumbs';
import { definePropsString } from 'lib/define-prop-strings';
import { useAmp } from 'next/amp';
import { PageCategoriesList } from 'components/more-category-posts/more-category-posts';
import { HeroBlockContextProvider } from 'components/success-stories/hero-block/hero-block.context-provider';
import { HeroBlockClipPath } from 'components/success-stories/hero-block/hero-block-clip-path';

function setupSeoTitle(title?: string) {
    return title ? `Download from article - ${title}` : 'Download app Footer';
}

function PageSectionSocialSharing() {
    return (
        <PageSection
            inner={false}
            theme={PageSection.Theme.NONE}
            hasLimitedWidth={true}
            extraClass="page-section--footer-cta"
        >
            <P1>
                <b>Share this article</b>
            </P1>
            <br />
            <SocialLinks
                items={[
                    {
                        type: 'facebook',
                        icon: <Icon size="md" name={Icon.Name.Facebook} />,
                    },
                    {
                        type: 'twitter',
                        icon: <Icon size="md" name={Icon.Name.Twitter} />,
                    },
                    {
                        type: 'pinterest',
                        icon: <Icon size="md" name={Icon.Name.Pinterest} />,
                    },
                    {
                        type: 'linkedin',
                        icon: <Icon size="md" name={Icon.Name.Linkedin} />,
                    },
                ]}
            />
        </PageSection>
    );
}

export enum PageTemplateContentType {
    Index = 'Index',
    Archive = 'Archive',
    Article = 'Article',
}

type renderPageElementProps = {
    condition: boolean;
    children?: React.ReactNode;
};
function renderPageElement(props: renderPageElementProps) {
    return props.condition ? props.children : null;
}

type PageTemplateContentProps = {
    id?: Scalars['Int'];
    lang: SupportedLanguage;
    type: PageTemplateContentType;
    intro?: React.ReactNode;
    categories?: Maybe<RootQueryToCategoryConnection>;
    pageCategoriesList?: PageCategoriesList;
    children?: React.ReactNode;
    modal?: React.ReactNode;
    title?: string;
    browserTab?: string;
    seoTitle?: string;
    seoDescription?: string;
    seoUrl?: string;
    openTitle?: string;
    openDescription?: string;
    datePublished?: string;
    dateModified?: string;
    // @TODO: add support in CMS
    metaKeywords?: string | null;
    metaRobotsNoindex?: string | null;
    metaRobotsNofollow?: string | null;
    opengraphPublishedTime?: string | null;
    opengraphModifiedTime?: string | null;
    openImageUrl?: string | null;
    openImageAlt?: string | null;
    heroImageUrl?: string | null;
    heroImageAlt?: string | null;
    heroImageHeight?: string | null;
    heroImageWidth?: string | null;
    openSiteName?: string;
    hasFooterCta?: boolean;
    footerTitleText?: Maybe<string> | undefined;
    hasFooterCtaButton?: boolean;
    ctaButtonText?: Maybe<string> | undefined;
    ctaButtonUrl?: Maybe<string> | undefined;
    ctaButtonTargetNewWindow?: boolean;
    onPageClick?: () => void;
    dynamicFooter?: boolean;
    geoIpCountry?: string | null;
    pageUrl?: string;
    articleUrl?: string;
} & PageSEOPropsType;

function PageTemplateContent(props: PageTemplateContentProps) {
    const isAmp = useAmp();

    return (
        <HeroBlockContextProvider>
            {renderPageElement({
                condition: props.type === PageTemplateContentType.Article,
                children: (
                    <>
                        <StructuredDataElementPage
                            url={definePropsString(props.articleUrl)}
                            category={definePropsString(
                                props.categories &&
                                    props.categories.edges?.map((edge) => edge?.node?.name)[0]
                            )}
                            title={definePropsString(props.title)}
                            openImageUrl={definePropsString(props.openImageUrl)}
                            opengraphPublishedTime={definePropsString(props.opengraphPublishedTime)}
                            opengraphModifiedTime={definePropsString(props.opengraphModifiedTime)}
                        />

                        <HeroComponent
                            priority={true}
                            type={HeroComponent.type.image}
                            url={definePropsString(props.heroImageUrl)}
                            alt={props.heroImageAlt || props.openImageAlt || props.title}
                            fullWidth={true}
                            contentVerticalPosition={HeroComponent.contentVerticalAlignment.center}
                            contentHorizontalPosition={
                                HeroComponent.contentHorizontalAlignment.center
                            }
                            height={
                                props.heroImageHeight ? parseInt(props.heroImageHeight, 10) : 700
                            }
                            width={props.heroImageWidth ? parseInt(props.heroImageWidth, 10) : 1200}
                            hasFrame={true}
                            mode={HeroComponent.mode.BFF}
                        />

                        <PageSection
                            inner={false}
                            theme={PageSection.Theme.NONE}
                            hasLimitedWidth={true}
                            hasPadding={true}
                            extraClass="page-section--article-intro"
                        >
                            <div className="page__breadcrumbs">
                                <Breadcrumbs
                                    list={setBreadCrumbs({
                                        categories: props.categories,
                                        name: props.title,
                                        slug: props.pageUrl,
                                    })}
                                />
                            </div>
                            <Header2 tagname={TextTagName.h1}>{props.title}</Header2>
                        </PageSection>
                    </>
                ),
            })}

            {renderPageElement({
                condition: Boolean(props.type !== PageTemplateContentType.Article),
                children: props.heroImageUrl && (
                    <HeroComponent
                        priority={true}
                        type={HeroComponent.type.image}
                        url={props.heroImageUrl}
                        alt={props.heroImageAlt || props.openImageAlt || props.title}
                        fullWidth={true}
                        contentVerticalPosition={HeroComponent.contentVerticalAlignment.center}
                        contentHorizontalPosition={HeroComponent.contentHorizontalAlignment.center}
                        hasFrame={true}
                        mode={HeroComponent.mode.BFF}
                    >
                        {props.intro}
                    </HeroComponent>
                ),
            })}

            {props.type === PageTemplateContentType.Article ? (
                <>
                    <div className="page__article-content">{props.children}</div>

                    <PageSection
                        inner={false}
                        theme={PageSection.Theme.NONE}
                        hasLimitedWidth={true}
                        hasPadding={true}
                        extraClass="page-section--footer-cta"
                    >
                        <Button
                            labelText={props.ctaButtonText}
                            color={Button.color.Primary}
                            href={props.ctaButtonUrl || downloadUrl}
                            text={props.ctaButtonText}
                            designType={Button.designType.FILLED}
                            extraClass="button--footer-cta button--color-primary button--color-primary-stroke"
                            seo={{
                                category: 'bumble-friendship-hub Navigation',
                                action: 'Button Click',
                                label: setupSeoTitle(props.title),
                            }}
                            onClick={(event) => clickEventFromElement(event)}
                        />
                    </PageSection>

                    {isAmp ? null : <PageSectionSocialSharing />}
                </>
            ) : (
                props.children
            )}

            {props.type === PageTemplateContentType.Index && props.pageCategoriesList ? (
                <PageIndexCategoryList
                    pageCategoriesList={props.pageCategoriesList}
                    backgroundColor="#fff"
                    hideTitle={true}
                />
            ) : null}

            {props.type === PageTemplateContentType.Archive && props.pageCategoriesList ? (
                <PageArchiveCategoryList
                    backgroundColor="#fff"
                    breadcrumbs={
                        <Breadcrumbs
                            list={setBreadCrumbs({
                                categories: props.categories,
                                name: props.title,
                                slug: props.pageUrl,
                            })}
                        />
                    }
                    pageCategoriesList={props.pageCategoriesList}
                    filter={definePropsString(props.title)}
                    titleUrl={props.pageUrl}
                />
            ) : null}

            {renderPageElement({
                condition: Boolean(props.type === PageTemplateContentType.Article && !isAmp),
                children: props.pageCategoriesList && (
                    <PageIndexCategoryList
                        pageCategoriesList={props.pageCategoriesList}
                        filter={props.id}
                        isRelatedArticles={true}
                        hideCta={true}
                    />
                ),
            })}
            <HeroBlockClipPath />
        </HeroBlockContextProvider>
    );
}
PageTemplateContent.type = PageTemplateContentType;

export default PageTemplateContent;
