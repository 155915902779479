import * as React from 'react';
import { useRouter } from 'next/router';
import { baseUrl } from 'lib/base-url';
import Image from 'lib/image-container';
import { StructuredData } from 'components/structured-data';
import { BlockProps } from '../index.types';
import { updateImagePaths } from 'lib/updateImagePaths';
import PageSection from 'components/page/page-section';
import { Header3 } from 'components/typography';
import { Paragraph } from 'components/Blocks/Paragraph/Paragraph';

type FaqElement = {
    answerType: string | FaqAnswerType;
    id: string;
};

type FaqAnswerType = {
    key: null;
    type: 'img';
    props: {
        alt: string;
        className: string;
        src: string;
    };
    ref: null;
    _owner: null;
};

type FaqQuestionType = {
    id: string;
    question: string[];
    jsonQuestion: string;
    jsonAnswer: string;
    answer: string[] | FaqAnswerType[];
};

type parsedFaqQuestionType = FaqQuestionType & {
    url: string;
};

function setAnswerElement(faqElement: FaqElement, index: number) {
    let answerContent: React.ReactChild = '';

    if (typeof faqElement.answerType === 'string') {
        answerContent = (
            <div className="schema-faq__question-answer-faq">
                <Paragraph block={{ innerHTML: faqElement.answerType }} />
            </div>
        );
    } else if (typeof faqElement.answerType !== 'string' && faqElement.answerType.type === 'img') {
        // @TODO: add support for JSON-DATA for images
        answerContent = (
            <Image
                className="schema-faq__question-answer-type-image"
                src={updateImagePaths(faqElement.answerType.props.src)}
                alt={faqElement.answerType.props.alt}
            />
        );
    }

    return (
        <React.Fragment
            key={`${faqElement.id}-${
                typeof faqElement.answerType !== 'string' ? faqElement.answerType.type : null
            }-${index}`}
        >
            {answerContent}
        </React.Fragment>
    );
}

function setParsedQuestion({ id, jsonQuestion, answer }: parsedFaqQuestionType) {
    return (
        <React.Fragment key={`schema-faq-question-${id}`}>
            <li className="schema-faq__question" id={id} key={id}>
                <div className="schema-faq__question-text">
                    <Header3 html={jsonQuestion} />
                </div>
                <div className="schema-faq__question-answer">
                    {answer.map((answerType, index) => setAnswerElement({ answerType, id }, index))}
                </div>
            </li>
        </React.Fragment>
    );
}

export function FAQList({ questions }: BlockProps) {
    const router = useRouter();
    const url = `${baseUrl}${router.basePath}${router.asPath}`;
    const parsedQuestions = (questions && JSON.parse(questions)) || [];

    const questionsList = parsedQuestions.map((parsedQuestion: FaqQuestionType) =>
        setParsedQuestion({ url, ...parsedQuestion })
    );

    return (
        <PageSection hasLimitedWidth={true} inner={false} hasPadding={true}>
            <ul className="schema-faq">{questionsList}</ul>
            <StructuredData
                id="faq-page"
                type={StructuredData.type.FAQPage}
                data={{
                    items: parsedQuestions.map(({ jsonQuestion, answer }: FaqQuestionType) => {
                        return {
                            name: jsonQuestion,
                            // @TODO update to include any string or image
                            item: typeof answer[0] === 'string' ? answer[0] : '',
                        };
                    }),
                }}
            />
        </PageSection>
    );
}
