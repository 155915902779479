import * as React from 'react';
import { BlockProps } from '../index.types';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import PageSection from 'components/success-stories/page-section/page-section';
import { Columns, ColumnsItem } from 'components/columns';
import { Header2, Header4 } from 'components/typography';
import Button from 'components/button/button';
import MediaBox, { MediaBoxAspect } from 'components/media-box/media-box';
import CtaBox from 'components/cta-box/cta-box';
import CtaBoxTitle, { CtaBoxTitleAlign } from 'components/cta-box/cta-box-title';
import CtaBoxSubtitle, { CtaBoxSubTitleAlign } from 'components/cta-box/cta-box-subtitle';
import CtaBoxCaption, { CtaBoxCaptionAlign } from 'components/cta-box/cta-box-caption';
import { clickEventFromElement } from 'components/google-analytics/google-analytics';

type BlockPropsType = {
    block: BlockProps;
} & {
    title?: string;
    subTitle?: string;
    text?: string;
    ctaText?: string;
    clatLink?: string;
    // @TODO add html based check
    ctaTarget?: string;
    mediaURL?: string;
    // @TODO add noreferrer - here and at BE ?
};

export default function CtatwoColumnsSection({ block, ...props }: BlockPropsType) {
    const isBreakpoint = useMediaQuery(MediaQueryWidth['tablet-md']);

    return (
        <PageSection bg={PageSection.Background.SECONDARY} hasPadding={true}>
            <Columns hasMobileLayout={true} gap={isBreakpoint ? 24 : 136}>
                {props.mediaURL ? (
                    <ColumnsItem size={ColumnsItem.size['1/1']}>
                        <div className="success-story__media">
                            <div className="success-story__image success-story__image--full">
                                <MediaBox aspect={MediaBoxAspect.FREE} src={props.mediaURL} />
                            </div>
                        </div>
                    </ColumnsItem>
                ) : null}
                <ColumnsItem size={ColumnsItem.size['1/1']}>
                    <div style={{ maxWidth: isBreakpoint ? undefined : 440 }}>
                        <CtaBox>
                            <>
                                {props.title ? (
                                    <CtaBoxTitle
                                        align={isBreakpoint ? CtaBoxTitleAlign.CENTERED : undefined}
                                    >
                                        <Header4 html={props.title} />
                                    </CtaBoxTitle>
                                ) : null}
                            </>

                            <>
                                {props.subTitle ? (
                                    <CtaBoxSubtitle
                                        align={
                                            isBreakpoint ? CtaBoxSubTitleAlign.CENTERED : undefined
                                        }
                                        marginTop={8}
                                    >
                                        <Header2 html={props.subTitle} />
                                    </CtaBoxSubtitle>
                                ) : null}
                            </>

                            <>
                                {props.ctaText && props.clatLink ? (
                                    <CtaBoxCaption
                                        align={
                                            isBreakpoint ? CtaBoxCaptionAlign.CENTERED : undefined
                                        }
                                        marginTop={isBreakpoint ? 24 : 32}
                                    >
                                        <Button
                                            extraClass="button--cta-read-more button--cta-primary"
                                            size={
                                                isBreakpoint
                                                    ? Button.size.MEDIUM
                                                    : Button.size.LARGE
                                            }
                                            text={props.ctaText}
                                            href={props.clatLink}
                                            target={props.ctaTarget}
                                            seo={{
                                                category: 'Navigation',
                                                action: 'Button Click',
                                                label: `${props.ctaText} - ${props.clatLink}`,
                                            }}
                                            onClick={(event) => clickEventFromElement(event)}
                                        />
                                    </CtaBoxCaption>
                                ) : null}
                            </>
                        </CtaBox>
                    </div>
                </ColumnsItem>
            </Columns>
        </PageSection>
    );
}
