import * as React from 'react';
import cx from 'classnames';
import { Header3 } from 'components/typography';
import { Category as CategoryType, Post } from 'interfaces/microsite-cms';
import { basePathIndexUrl } from 'lib/schemes';
import CtaBoxCaption, { CtaBoxCaptionAlign } from 'components/cta-box/cta-box-caption';
import CtaBox, { CtaBoxAlign } from 'components/cta-box/cta-box';

export type CategoryEdgePostEdge = {
    node: Post & {
        postFieldGroup: {
            ctaText: string;
            ctaUrl: string;
        };
    };
};

export type CategoryEdge = {
    node: CategoryType & {
        postCategoryFieldGroup: {
            order: number;
            backgroundcolor?: string;
            ctaText: string;
            ctaUrl: string;
            showonmainpage: boolean;
        };
        posts: {
            edges: CategoryEdgePostEdge[];
        };
    };
};

export type CategoryPostItem = {
    node: Post & {
        postFieldGroup: {
            ctaText: string;
            ctaUrl: string;
        };
    };
};

type CategoryProps = {
    hideTitle?: boolean;
    titleUrl?: string;
    breadcrumbs?: React.ReactNode;
    cta?: React.ReactNode;
    loader?: React.ReactNode;
    name?: string;
    slug?: string;
    edges?: CategoryEdge[] | React.ReactNode[];
    ctaUrl?: string;
    ctaText?: string;
    backgroundColor?: string;
    hasOneRow?: boolean;
    isRelatedArticles?: boolean;
};

export function Category({
    hideTitle,
    // titleUrl,
    breadcrumbs,
    name,
    // slug,
    edges,
    backgroundColor,
    hasOneRow,
    cta,
    loader,
    isRelatedArticles,
}: CategoryProps) {
    const className = cx({
        category: true,
        'category--one-row': hasOneRow,
        'category--related-articles': isRelatedArticles,
        [`category--${name?.toLocaleLowerCase()}`]: name,
    });

    const title = isRelatedArticles ? `More Friendship Ideas` : name;
    const url = `${basePathIndexUrl}`;
    // const url =
    //     titleUrl || slug?.startsWith('/bff/friendship') ? `${slug}` : `${basePathIndexUrl}/${slug}`;

    return (
        <div
            className={className}
            style={{
                backgroundColor,
            }}
        >
            <div className="category__breadcrumbs">{breadcrumbs}</div>
            {!hideTitle ? (
                <div className="category__header">
                    {title ? (
                        <Header3>
                            <a href={url}>{title}</a>
                        </Header3>
                    ) : null}
                </div>
            ) : null}

            <div className="category__content">
                <div className="category__cards">{edges}</div>
            </div>

            {loader ? <div className="category__cta">{loader}</div> : null}

            {cta ? (
                <div className="category__cta">
                    <CtaBox align={CtaBoxAlign.CENTERED}>
                        <CtaBoxCaption align={CtaBoxCaptionAlign.CENTERED}>
                            <>{cta}</>
                        </CtaBoxCaption>
                    </CtaBox>
                </div>
            ) : null}
        </div>
    );
}
