import * as React from 'react';
import { BlockProps } from '../index.types';
import TextSection from 'components/success-stories/text-section/text-section';
import PageSection from 'components/success-stories/page-section/page-section';

type TextSectionBlockType = {
    block: BlockProps;
} & {
    title?: string;
    text?: string;
};

export default function TextSectionBlock({ block, ...props }: TextSectionBlockType) {
    return (
        <PageSection bg={PageSection.Background.PRIMARY} hasPadding={true}>
            <TextSection titleCenter={false} title={props.title} text={props.text} />
        </PageSection>
    );
}
