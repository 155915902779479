import { IncomingHttpHeaders } from 'http';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';
import { getDataFactory } from 'lib/api/get-data';

export async function trackClick(
    headers: IncomingHttpHeaders,
    url?: string | undefined,
    lang?: SupportedLanguage
) {
    const filteredUrl = url?.replace(/index-lang/g, '');
    /**
     * SEOClick tracking by sending request to our internal servers
     * with client headers, so it emulates real event.
     * Currently using dummy page to try out the concept.
     * We probably need to clear the headers in future before passing it further
     */
    const headersForGet = {
        ...headers,
        // Referer will be provided by real request
        // Referer: 'https://www.google.com',
        'X-Query-Name': 'SEO_CICK',
        'X-Query-Request-Uri': filteredUrl ? `/bff/friendship${filteredUrl}` : '/bff/friendship',
        'X-Query-Request-Host': 'bumble.com',
        // Bumble - 4
        // 'X-Query-Request-Brand': '4',
        // App Version
        // 'X-Query-Request-App-Version': 'v0.0.1',
    };

    if (process.env.SEO_DATA_ORIGIN_HOST && !url?.includes('_next')) {
        try {
            getDataFactory(
                'internal-api/api-events-web-hook',
                lang || 'en',
                process.env.SEO_DATA_ORIGIN_HOST,
                headersForGet
            );
        } catch (error) {
            console.log(`track click error:`, JSON.stringify(error));
        }
    }
}
