import ReactGA from 'react-ga';
import { log } from 'lib/log';

export const UA_ID = 'UA-69709700-1';

export function initialize() {
    ReactGA.initialize(UA_ID);
    ReactGA.set({ anonymizeIp: true, cookieExpires: 31536000 });
}

export function pageView() {
    const page = window.location.pathname + window.location.search;

    log(`page view => ${page}`);

    ReactGA.pageview(page);
}

export function clickEventFromElement(event: React.MouseEvent<HTMLAnchorElement>) {
    const {
        seoCategory = '',
        seoAction = '',
        seoLabel = '',
        seoValue,
        nonInteraction = false,
    } = event.currentTarget.dataset;

    const eventData = {
        category: seoCategory,
        action: seoAction,
        label: seoLabel,
        value:
            seoValue && typeof Number(parseInt(seoValue, 10)) === 'number'
                ? parseInt(seoValue, 10)
                : 0,
        nonInteraction: Boolean(nonInteraction),
    };

    log(`Click Event data => ${JSON.stringify(eventData)}`);

    ReactGA.event(eventData);
}

export function clickEventfromOptions({
    seoCategory = '',
    seoAction = '',
    seoLabel = '',
    seoValue = '',
    nonInteraction = false,
}) {
    const eventData = {
        category: seoCategory,
        action: seoAction,
        label: seoLabel,
        value:
            seoValue && typeof Number(parseInt(seoValue, 10)) === 'number'
                ? parseInt(seoValue, 10)
                : 0,
        nonInteraction: Boolean(nonInteraction),
    };

    log(`Click Event data => ${JSON.stringify(eventData)}`);

    ReactGA.event(eventData);
}
