/**
 * Determinmes the country from GeoIP
 *
 * Example:
 *
    export const getServerSideProps: GetServerSideProps<Props> = async function ({ req }) {
        return {
            props: {
                geoIpCountry: getGeoCountry(req),
            },
        };
    };
 */
import { IncomingMessage } from 'http';

export function getGeoCountry(req?: IncomingMessage) {
    const geoIpCountry = req?.headers['x-geo-country'];
    return typeof geoIpCountry === 'string' ? parseGeoIpCountry(geoIpCountry) : null;
}

// In nginx we send multiple geo ip countries separated by a comma
// this function determines the correct country
// e.g. *,*,GB_13,*,*
function parseGeoIpCountry(countryHeader: string) {
    const country = countryHeader.split(',').find((countryFromHeader) => countryFromHeader !== '*');

    if (!country) {
        return null;
    }

    // country will be of the format STRCODE_NUMCODE (e.g. GB_13)
    const countryStrCode = country.split('_')[0];

    if (!countryStrCode) {
        return null;
    }

    return countryStrCode.toLowerCase();
}
