import * as React from 'react';
// import { useRouter } from 'next/router';
// import { baseUrl } from 'lib/base-url';
// import Image from 'lib/image-container';
import { StructuredData } from 'components/structured-data';
import { BlockProps, HowToStepBlock, HowToStepTextBlock } from 'components/Blocks/index.types';
// import { updateImagePaths } from 'lib/updateImagePaths';
import PageSection from 'components/page/page-section';
import { Header3, Header4, P1 } from 'components/typography';
import HeroImage from 'components/hero-image/hero-image';
import { convertToNextImage, updateImagePaths } from 'lib/updateImagePaths';
import { useRouter } from 'next/router';
import { baseUrl } from 'lib/base-url';

function setHowToTotalTime(days?: string, hours?: string, minutes?: string) {
    /**
     * https://en.wikipedia.org/wiki/ISO_8601#Durations
     */
    if (!days && !hours && !minutes) {
        return 'PT15M';
    }

    let duration = 'P';

    if (days) {
        duration += `${days}D`;
    }

    if (hours || minutes) {
        duration += `T`;
    }

    if (hours) {
        duration += `${hours}H`;
    }

    if (minutes) {
        duration += `${minutes}M`;
    }

    return duration;
}

export function HowTo({
    hasDuration,
    days,
    hours,
    minutes,
    jsonDescription,
    steps,
    durationText,
    defaultDurationText,
}: BlockProps) {
    const parsedSteps: HowToStepBlock[] = steps && JSON.parse(steps);
    const router = useRouter();
    const url = `${baseUrl}${router.basePath}${router.asPath}`;

    return (
        <PageSection hasLimitedWidth={true} inner={false} hasPadding={true}>
            {jsonDescription ? <Header3>{jsonDescription}</Header3> : null}
            {hasDuration ? (
                <P1 extraClass="schema-faq__description">
                    {durationText || defaultDurationText} {days}:{hours}:{minutes}
                </P1>
            ) : null}

            <ol className="schema-faq schema-faq--ordered">
                {parsedSteps.map(({ id, name, text }: HowToStepBlock) => {
                    // @TODO move via let to one key solution
                    return (
                        <li className="schema-faq__question" id={id} key={id}>
                            <Header4>{name[0]}</Header4>
                            {text.map((textElement: HowToStepTextBlock) => {
                                if (typeof textElement === 'string') {
                                    return <P1 key={`${textElement}-${id}`}>{textElement}</P1>;
                                }

                                if (textElement.type === 'img' && textElement.props?.src) {
                                    const src = convertToNextImage(
                                        updateImagePaths(textElement.props?.src)
                                    );
                                    return <HeroImage key={`image-${id}`} src={src} />;
                                }

                                return <P1 key={`image-${id}`}>{textElement.props?.src}</P1>;
                            })}
                        </li>
                    );
                })}
            </ol>
            <StructuredData
                id="howto-page"
                type={StructuredData.type.HowTo}
                data={{
                    jsonDescription: jsonDescription || '',
                    steps: parsedSteps,
                    totalTime: setHowToTotalTime(days, hours, minutes),
                    url,
                }}
            />
        </PageSection>
    );
}
