import * as React from 'react';
import { BlockProps } from '../index.types';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import PageSection from 'components/success-stories/page-section/page-section';
import { Header2, Header4 } from 'components/typography';
import Button from 'components/button/button';
import MediaBox, { MediaBoxAspect } from 'components/media-box/media-box';
import CtaBox, { CtaBoxAlign } from 'components/cta-box/cta-box';
import CtaBoxTitle from 'components/cta-box/cta-box-title';
import CtaBoxSubtitle from 'components/cta-box/cta-box-subtitle';
import CtaBoxCaption, { CtaBoxCaptionAlign } from 'components/cta-box/cta-box-caption';
import CtaBoxMedia, { CtaBoxMediaAlign } from 'components/cta-box/cta-box-media';
import FlexImageList from 'components/success-stories/flexible-image-list/flexible-image-list';
import { clickEventFromElement } from 'components/google-analytics/google-analytics';

type BlockPropsType = {
    block: BlockProps;
} & {
    title?: string;
    subTitle?: string;
    text?: string;
    ctaText?: string;
    clatLink?: string;
    // @TODO add html based check
    ctaTarget?: string;
    mediaURLOne?: string;
    mediaURLTwo?: string;
    mediaURLThree?: string;
    mediaURLFour?: string;
    // @TODO add noreferrer - here and at BE ?
};

export default function CtaInstagramFeedSection({ block, ...props }: BlockPropsType) {
    const isBreakpoint = useMediaQuery(MediaQueryWidth['tablet-md']);

    return (
        <PageSection bg={PageSection.Background.ACCENT} hasPadding={true}>
            <CtaBox align={CtaBoxAlign.CENTERED}>
                <>
                    {props.title ? (
                        <CtaBoxTitle>
                            <Header2>{props.title}</Header2>
                        </CtaBoxTitle>
                    ) : null}
                </>

                <>
                    {props.subTitle ? (
                        <CtaBoxSubtitle marginTop={8}>
                            <Header4 html={props.subTitle} />
                        </CtaBoxSubtitle>
                    ) : null}
                </>

                <CtaBoxMedia
                    fullWidth={true}
                    align={CtaBoxMediaAlign.CENTERED}
                    marginTop={isBreakpoint ? 24 : 32}
                >
                    <FlexImageList grid={FlexImageList.Grid.FOUR}>
                        {props.mediaURLOne ? (
                            <MediaBox aspect={MediaBoxAspect.SQUARE} src={props.mediaURLOne} />
                        ) : null}

                        {props.mediaURLTwo ? (
                            <MediaBox aspect={MediaBoxAspect.SQUARE} src={props.mediaURLTwo} />
                        ) : null}

                        {props.mediaURLThree ? (
                            <MediaBox aspect={MediaBoxAspect.SQUARE} src={props.mediaURLThree} />
                        ) : null}

                        {props.mediaURLFour ? (
                            <MediaBox aspect={MediaBoxAspect.SQUARE} src={props.mediaURLFour} />
                        ) : null}
                    </FlexImageList>
                </CtaBoxMedia>

                <>
                    {props.ctaText && props.clatLink ? (
                        <CtaBoxCaption
                            align={CtaBoxCaptionAlign.CENTERED}
                            marginTop={isBreakpoint ? 24 : 32}
                        >
                            <Button
                                extraClass="button--cta-read-more button--cta-read-more-contrast"
                                text={props.ctaText}
                                href={props.clatLink}
                                target={props.ctaTarget}
                                seo={{
                                    category: 'Navigation',
                                    action: 'Button Click',
                                    label: props.ctaText,
                                }}
                                onClick={(event) => clickEventFromElement(event)}
                            />
                        </CtaBoxCaption>
                    ) : null}
                </>
            </CtaBox>
        </PageSection>
    );
}
