import { PostContentBlockMap } from 'components/Blocks/index.types';
import HeroBlock from 'components/Blocks/HeroBlock/HeroBlock';
import TextSection from 'components/Blocks/TextSection/TextSection';
import CtaSliderSection from 'components/Blocks/CtaSliderSection/CtaSliderSection';
import CtatwoColumnsSection from 'components/Blocks/CtatwoColumnsSection/CtatwoColumnsSection';
import CtaInstagramFeedSection from 'components/Blocks/CtaInstagramFeedSection/CtaInstagramFeedSection';
import IframeSection from 'components/Blocks/IframeSection/IframeSection';
import SuccessStories from 'components/Blocks/SuccessStories/SuccessStories';
import { Paragraph } from 'components/Blocks/Paragraph/Paragraph';
import { List } from 'components/Blocks/List/List';
import { ListItem } from 'components/Blocks/List/ListItem';
import { Heading } from 'components/Blocks/Heading/Heading';
import { HowTo } from 'components/Blocks/HowTo/HowTo';
import { FAQList } from 'components/Blocks/FAQList/FAQList';
import PageSection from 'components/Blocks/PageSection/PageSection';
import { Image } from 'components/Blocks/Image/Image';

const defaultBlockMap: PostContentBlockMap = {
    'bumble-success-stories/hero-block': HeroBlock,
    'bumble-success-stories/text-section': TextSection,
    'bumble-success-stories/cta-two-columns-section': CtatwoColumnsSection,
    'bumble-success-stories/cta-slider-section': CtaSliderSection,
    'bumble-success-stories/cta-instagram-feed-section': CtaInstagramFeedSection,
    'bumble-success-stories/success-stories': SuccessStories,
    'bumble-success-stories/iframe-section': IframeSection,
    // 'bumble-friendship-hub/intro-section'
    'bumble-friendship-hub/page-section': PageSection,
    'yoast/how-to-block': HowTo,
    'yoast/faq-block': FAQList,
    'core/heading': Heading,
    'core/image': Image,
    'core/paragraph': Paragraph,
    'core/list': List,
    'core/list-item': ListItem,
};

export default defaultBlockMap;
