import * as React from 'react';
import { BlockProps } from '../index.types';
import HeroComponent from 'components/success-stories/hero-block/hero-block';

type HeroBlockType = {
    block: BlockProps;
} & {
    alt?: string;
    title?: string;
    mediaType?: 'video' | 'image';
    mediaURL?: string;
};

export default function HeroBlock({ block, ...props }: HeroBlockType) {
    if (!props.mediaURL) {
        return <p>No media file was found</p>;
    }

    return (
        <HeroComponent
            title={props.title}
            type={props.mediaType ? HeroComponent.type.video : HeroComponent.type.image}
            alt={props.alt}
            url={props.mediaURL}
        />
    );
}
