import { HowToStepBlock, HowToStepTextBlock } from 'components/Blocks/index.types';
import { cleanUrlFromParams } from 'lib/set-url-params';
import { convertToNextImage, updateImagePaths } from 'lib/updateImagePaths';

export type StructuredDataTemplateItemList = {
    items: {
        item: string;
    }[];
};

export type StructuredDataTemplateHowTo = {
    jsonDescription: string;
    totalTime: string;
    url: string;
    steps: HowToStepBlock[];
};

export type StructuredDataTemplateBreadCrumbList = {
    url: string;
    items: {
        name: string;
        item: string;
        order: number;
    }[];
};

export type StructuredDataTemplateFaqPage = {
    items: {
        name: string;
        item: string;
    }[];
};

export type StructuredDataTemplateQuestion = {
    url: string;
    id: string;
    order: string;
    question: string;
    answer: string;
};

export type StructuredDataTemplateArticle = {
    absUrl: string;
    url: string;
    title: string;
    category: string;
    imageUrl: string;
    datePublished: string;
    dateModified: string;
};

export type StructuredDataTemplateList =
    | StructuredDataTemplateQuestion
    | StructuredDataTemplateItemList
    | StructuredDataTemplateHowTo
    | StructuredDataTemplateFaqPage
    | StructuredDataTemplateBreadCrumbList
    | StructuredDataTemplateArticle;

export function setStructuredDataTemplateQuestion(props: StructuredDataTemplateQuestion) {
    // acceptedAnswer - text -escaped html
    // inLanguage
    const url = cleanUrlFromParams(props.url);
    return `{
        "@context": "https://schema.org",
        "@type": "Question",
        "@id": "${url}/#${props.id}",
        "position": ${props.order},
        "url": "${url}/#${props.id}",
        "name": "${props.question}",
        "answerCount": 1,
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "${props.answer}",
            "inLanguage": "en-US"
        },
        "inLanguage": "en-US"
    }`;
}

export function setStructuredDataTemplateBreadCrumbList(
    props: StructuredDataTemplateBreadCrumbList
) {
    const url = cleanUrlFromParams(props.url);
    return `{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "@id": "${url}/#breadcrumbs",
        "itemListElement": [
            ${props.items.map(({ name, item, order }) => {
                return `
                {
                    "@type": "ListItem",
                    "position": "${order + 1}",
                    "name": "${name}",
                    "item": "${item}"
                }
                `;
            })}
        ]
    }`;
}

export function setStructuredDataTemplateItemList(props: StructuredDataTemplateItemList) {
    // @TODO - add name
    return `{
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListOrder": "https://schema.org/ItemListOrderDescending",
        "itemListElement": [
            ${props.items.map(({ item }) => `${item}`)}
        ]
    }`;
}

type HowTOStepTextElement = {
    '@type': string;
    position: string;
    url?: string;
    name?: string;
    image?: string;
    itemListElement: {
        '@type': string;
        position: '1';
        text?: string;
        duringMedia?: string;
    }[];
};

function setTextElementFromArray(text: HowToStepTextBlock[], index: number, url: string) {
    const props: HowTOStepTextElement = {
        '@type': 'HowToStep',
        position: `${index}`,
        itemListElement: [
            {
                '@type': 'HowToDirection',
                position: '1',
            },
        ],
    };

    text.forEach((textElement) =>
        typeof textElement === 'string'
            ? (props.itemListElement[0].text = textElement)
            : textElement.props?.src &&
              (props.itemListElement[0].duringMedia = convertToNextImage(
                  updateImagePaths(textElement.props?.src)
              ))
    );

    props.url = url;
    props.name = props.itemListElement[0].text;

    if (props.itemListElement[0].duringMedia) {
        props.image = props.itemListElement[0].duringMedia;
    }

    return JSON.stringify(props);
}

export function setStructuredDataTemplateHowTo(props: StructuredDataTemplateHowTo) {
    // "position": "${index + 1}", perhaps => "position": "${1}",
    // @TODO - add name
    return `{
        "@context": "https://schema.org",
        "@type": "HowTo",
        "name": "${props.jsonDescription}",
        "totalTime": "${props.totalTime}",
        "step": [
            ${props.steps.map(
                (step: HowToStepBlock, index) => `
                {
                    "@type": "HowToSection",
                    "name": "${step.jsonName}",
                    "position": "${index + 1}",
                    "itemListElement": [
                        ${setTextElementFromArray(step.text, index + 1, `${props.url}/#${step.id}`)}
                    ]
                }
            `
            )}
        ]
    }`;
}

export function setStructuredDataTemplateArticle(props: StructuredDataTemplateArticle) {
    const url = cleanUrlFromParams(props.url);
    return ` {
        "@context": "https://schema.org",
        "@type": "Article",
        "@id": "${url}/#article",
        "isPartOf": {
            "@id": "${url}"
        },
        "headline": "${props.title}",
        "datePublished": "${props.datePublished}",
        "dateModified": "${props.dateModified}",
        "publisher": {
            "@id": "${props.absUrl}/#organization"
        },
        "image": {
            "@id": "${url}/#primaryimage"
        },
        "thumbnailUrl": "${convertToNextImage(props.imageUrl)}",
        "articleSection": [
            "${props.category}"
        ],
        "inLanguage": "en-US",
        "author": {
            "@type": "Organization",
            "name": "Bumble",
            "url": "https://bumble.com/#organization"
        },
        "potentialAction": [
            {
                "@type": "ReadAction",
                "target": [
                    "${url}"
                ]
            }
        ]
    }`;
}

export function setStructuredDataTemplateFaqPage(props: StructuredDataTemplateFaqPage) {
    return `{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
            ${props.items.map(({ name, item }) => {
                return `
                {
                    "@type": "Question",
                    "name": "${name}",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "${item}"
                    }
                }
                `;
            })}
        ]
    }`;
}

// [StructuredDataType.Organization]: setStructuredDataTemplateQuestion,
// [StructuredDataType.WebSite]: setStructuredDataTemplateQuestion,
// [StructuredDataType.WebPage]: setStructuredDataTemplateQuestion,
// [StructuredDataType.ImageObject]: setStructuredDataTemplateQuestion,
