import React, { useContext } from 'react';
import cx from 'classnames';
import Link from 'components/link/link';
import LocaleContext from 'lib/locale-context';

type Props = {
    items?: {
        isActive?: boolean;
        headerDropDownMenu?: {
            getUrl(lang: string): string;
            title?: string | React.ReactNode;
        }[];
        url?: string;
        title?: string | React.ReactNode;
    }[];
};

// TODO: move menu separately
function HeaderMenu({ items }: Props) {
    const lang = useContext(LocaleContext);

    return (
        <ul className="header-menu">
            {items &&
                items.map((item, index) => {
                    const { isActive, headerDropDownMenu, url, title } = item;
                    const className = cx({
                        'header-menu__item': true,
                        'is-active': isActive,
                        'has-dropdown': headerDropDownMenu,
                    });

                    return (
                        <li className={className} key={`header-menu-item-${index}`}>
                            <Link className="header-menu__link" href={url}>
                                {title}
                            </Link>

                            {headerDropDownMenu ? (
                                <ul className="header-menu__dropdown">
                                    {headerDropDownMenu &&
                                        headerDropDownMenu.map((menuItem, dropdownIndex) => {
                                            const { getUrl, title: headerTitle } = menuItem;

                                            return (
                                                <li
                                                    className="header-menu__dropdown-item"
                                                    key={`header-menu--dropdown-item-${dropdownIndex}`}
                                                >
                                                    <Link
                                                        className="header-menu__dropdown-link"
                                                        href={getUrl(lang)}
                                                    >
                                                        {headerTitle}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                </ul>
                            ) : null}
                        </li>
                    );
                })}
        </ul>
    );
}

export default HeaderMenu;
