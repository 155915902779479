import * as React from 'react';
import { HeroBlockContext } from 'components/success-stories/hero-block/hero-block.context';

type HeroBlockContextProviderProps = {
    children: React.ReactNode;
};

type HeroBlockContextInitState = [number, number];

const INIT_STATE: HeroBlockContextInitState = [1, 1];

export function HeroBlockContextProvider(props: HeroBlockContextProviderProps) {
    const [opticCompensation, setOpticCompensation] =
        React.useState<HeroBlockContextInitState>(INIT_STATE);
    return (
        <HeroBlockContext.Provider value={{ opticCompensation, setOpticCompensation }}>
            {props.children}
        </HeroBlockContext.Provider>
    );
}
