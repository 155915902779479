import { StructuredData } from 'components/structured-data';
import * as React from 'react';
import { BlockProps } from '../index.types';
import PostContent from '../PostContent/PostContent';

export function List({ block: { innerBlocks } }: BlockProps) {
    // @TODO: confirm that the issue with hydration is due to innher html - perhaps we need to remove external wrapper?
    return innerBlocks ? (
        <>
            <ul className="list">
                <PostContent blocks={innerBlocks} />
            </ul>
            <StructuredData
                id=""
                type={StructuredData.type.ItemList}
                data={{
                    url: '',
                    items: innerBlocks.map((innerBlock) => {
                        return {
                            item: `"${innerBlock?.innerHTML}"`,
                        };
                    }),
                }}
            />
        </>
    ) : null;
}
