import { createContext } from 'react';

type HeroBlockContextValue = {
    opticCompensation: [number, number];
    setOpticCompensation: React.Dispatch<React.SetStateAction<[number, number]>>;
};

export const HeroBlockContext = createContext<HeroBlockContextValue>({
    opticCompensation: [1, 1],
    // eslint-disable-next-line no-empty-function
    setOpticCompensation: () => {},
});
