import * as React from 'react';
import { GetServerSidePropsContext } from 'next';
import {
    ContentBlock,
    Maybe,
    RootQueryToCategoryConnection,
    Scalars,
} from 'interfaces/microsite-cms';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';
import PageTemplate from 'components/page-template/page-template';
import { PageTemplateContentType } from 'components/page-template/page-template-content';
import PostContent from 'components/Blocks/PostContent/PostContent';
import { confirmDynamicUrl } from 'lib/confirm-url';
import { getGeoCountry } from 'lib/get-geo-country';
import { basePathIndexUrl } from 'lib/schemes';
import { getIndexPageProps, getCategoryPageProps, getArticlePageProps } from 'lib/get-page-props';
import { setupLangAndUrlParams } from 'lib/set-url-params';
import { PageCategoriesList } from 'components/more-category-posts/more-category-posts';
import { trackClick } from 'lib/api/track-click';

export type PageProps = {
    id?: Scalars['Int'];
    lang: SupportedLanguage;
    title: string;
    geoIpCountry?: string | null;
    blocks: ContentBlock[];
    description?: string;
    categories?: Maybe<RootQueryToCategoryConnection>;
    pageCategoriesList?: PageCategoriesList;
    imageUrl?: string | null;
    imageAlt?: string | null;
    imageHeight?: string | null;
    imageWidth?: string | null;
    seoTitle?: string | null;
    seoDescription?: string | null;
    datePublished?: string;
    dateModified?: string;
    // @TODO: add support in CMS
    metaKeywords?: string | null;
    metaRobotsNoindex?: string | null;
    metaRobotsNofollow?: string | null;
    opengraphPublishedTime?: string | null;
    opengraphModifiedTime?: string | null;
    type: PageTemplateContentType;
    pageUrl?: string;
    footerCtaText?: Maybe<Scalars['String']>;
    footerCtaUrl?: Maybe<Scalars['String']>;
};

export default function Page(props: PageProps) {
    const {
        id,
        lang,
        type,
        title,
        description,
        blocks,
        geoIpCountry,
        imageUrl,
        imageAlt,
        imageHeight,
        imageWidth,
        seoTitle,
        seoDescription,
        metaKeywords,
        metaRobotsNoindex,
        metaRobotsNofollow,
        opengraphPublishedTime,
        opengraphModifiedTime,
        categories,
        pageCategoriesList,
        footerCtaText,
        footerCtaUrl,
        pageUrl,
    } = props;
    const introBlock = blocks
        .filter((item) => item.name === 'bumble-seasonal-content-hub/intro-section')
        .map((item) => {
            if (item.innerBlocks) {
                return item.innerBlocks;
            }

            return item;
        })
        .flat();

    const otherBlocks = blocks.filter(
        (item) => item.name !== 'bumble-seasonal-content-hub/intro-section'
    );

    return (
        <PageTemplate
            id={id}
            lang={lang}
            type={type}
            // @TODO: add other seo data later: title, text, images
            browserTab={seoTitle || 'Bumble Seasonal Content Hub'}
            title={title}
            seoTitle={seoTitle || title}
            seoDescription={seoDescription || description}
            metaKeywords={metaKeywords}
            metaRobotsNoindex={metaRobotsNoindex}
            metaRobotsNofollow={metaRobotsNofollow}
            openTitle={seoTitle || title}
            openDescription={seoDescription || description}
            opengraphPublishedTime={opengraphPublishedTime}
            opengraphModifiedTime={opengraphModifiedTime}
            openImageUrl={imageUrl}
            openImageAlt={imageAlt}
            openImageHeight={imageHeight}
            openImageWidth={imageWidth}
            heroImageUrl={imageUrl}
            heroImageAlt={imageAlt}
            heroImageHeight={imageHeight}
            heroImageWidth={imageWidth}
            dynamicFooter={false}
            geoIpCountry={geoIpCountry}
            intro={<PostContent blocks={introBlock} />}
            categories={categories}
            pageCategoriesList={pageCategoriesList || undefined}
            // @TODO: change props
            ctaButtonText={footerCtaText}
            ctaButtonUrl={footerCtaUrl}
            pageUrl={pageUrl}
        >
            {blocks ? <PostContent blocks={otherBlocks} /> : null}
        </PageTemplate>
    );
}

export type GetServerSidePropsType = GetServerSidePropsContext<{ lang: SupportedLanguage }> & {
    locale?: SupportedLanguage;
};

/**
 * TODO : define props objects
 */
export const getServerSideProps = async function ({
    query,
    locale,
    res,
    req,
}: GetServerSidePropsType) {
    /**
     * Temporary fix
     * trying to avoid too many unnessary requests
     */
    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');
    /**
     * we only use lang, slug from query
     * & lang in getLocale(undefined, query)
     * cleanup query, so we won't parse any suspicious code
     */
    const { lang, url } = await setupLangAndUrlParams(
        {
            lang: query.lang,
            slug: query.slug,
        },
        locale
    );
    const knownUrl = await confirmDynamicUrl(url);
    if (!knownUrl) {
        return {
            notFound: true,
        };
    }

    const articleUrl =
        url.split('/').filter((urlPart) => urlPart).length === 2
            ? // if we don't have redirect in config - return 404
              `not-found`
            : `${url}`;

    const pageProps = await getArticlePageProps(articleUrl, lang);
    // @ts-ignore
    pageProps.type = PageTemplate.type.Article;
    // @ts-ignore
    pageProps.pageUrl = articleUrl;

    if (!pageProps.title) {
        return {
            notFound: true,
        };
    }

    trackClick(req.headers, req.url, lang);

    return {
        props: {
            geoIpCountry: getGeoCountry(req),
            ...pageProps,
        },
    };
};

export async function getCategoryPageServerSideProps(query: GetServerSidePropsType['query']) {
    const getCategoryPageServerSidePropsInner = async function ({
        locale,
        res,
        req,
    }: GetServerSidePropsType) {
        /**
         * Temporary fix
         * trying to avoid too many unnessary requests
         */
        res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

        const { lang, url } = await setupLangAndUrlParams(query, locale);
        const pageProps = await getCategoryPageProps(`${basePathIndexUrl}${url}`, lang);
        // @ts-ignore
        pageProps.type = PageTemplate.type.Archive;
        // @ts-ignore
        pageProps.pageUrl = `${basePathIndexUrl}${url}`;

        if (!pageProps.title) {
            return {
                notFound: true,
            };
        }

        trackClick(req.headers, req.url, lang);

        return {
            props: {
                geoIpCountry: getGeoCountry(req),
                ...pageProps,
            },
        };
    };

    return getCategoryPageServerSidePropsInner;
}

export const getIndexPageServerSideProps = async function ({
    query,
    locale,
    res,
    req,
}: GetServerSidePropsType) {
    /**
     * Temporary fix
     * trying to avoid too many unnessary requests
     */
    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');
    /**
     * we only use lang, slug from query
     * & lang in getLocale(undefined, query)
     */
    const { lang, url } = await setupLangAndUrlParams(query, locale);

    const pageProps = await getIndexPageProps(lang);
    // @ts-ignore
    pageProps.type = PageTemplate.type.Index;
    // @ts-ignore
    pageProps.pageUrl = `${basePathIndexUrl}${url}`;

    /**
     * if not index / category / article - we return not-found
     */
    if (!pageProps.title) {
        return {
            notFound: true,
        };
    }

    trackClick(req.headers, req.url, lang);

    return {
        props: {
            geoIpCountry: getGeoCountry(req),
            ...pageProps,
        },
    };
};

export const config = { amp: 'hybrid' };
