import * as React from 'react';
// import Head from 'next/head';
// import Script from 'next/script';
import {
    setStructuredDataTemplateQuestion,
    setStructuredDataTemplateFaqPage,
    setStructuredDataTemplateBreadCrumbList,
    StructuredDataTemplateList,
    setStructuredDataTemplateArticle,
    setStructuredDataTemplateItemList,
    setStructuredDataTemplateHowTo,
} from './structured-data-templates';

export enum StructuredDataType {
    Organization = 'Organization',
    WebSite = 'WebSite',
    WebPage = 'WebPage',
    BreadcrumbList = 'BreadcrumbList',
    Article = 'Article',
    ImageObject = 'ImageObject',
    FAQPage = 'FAQPage',
    Question = 'Question',
    ItemList = 'ItemList',
    HowTo = 'HowTo',
}

function prepareJSONforAmp(json: string) {
    let content = json;

    try {
        content = JSON.stringify(JSON.parse(json));
    } catch (error) {
        console.error(error);
    }

    return content;
}

export const mapStructuredDataTypeToStructuredTemplate: Record<StructuredDataType, Function> = {
    [StructuredDataType.Organization]: setStructuredDataTemplateQuestion,
    [StructuredDataType.WebSite]: setStructuredDataTemplateQuestion,
    [StructuredDataType.WebPage]: setStructuredDataTemplateQuestion,
    [StructuredDataType.BreadcrumbList]: setStructuredDataTemplateBreadCrumbList,
    [StructuredDataType.Article]: setStructuredDataTemplateArticle,
    [StructuredDataType.ImageObject]: setStructuredDataTemplateQuestion,
    [StructuredDataType.FAQPage]: setStructuredDataTemplateFaqPage,
    [StructuredDataType.HowTo]: setStructuredDataTemplateHowTo,
    [StructuredDataType.Question]: setStructuredDataTemplateQuestion,
    [StructuredDataType.ItemList]: setStructuredDataTemplateItemList,
};

function addJSONLDData({
    type,
    data,
}: {
    type: StructuredDataType;
    data: StructuredDataTemplateList;
}) {
    return {
        __html: prepareJSONforAmp(mapStructuredDataTypeToStructuredTemplate[type](data)),
    };
}

type StructuredDataProps = {
    id: string;
    type: StructuredDataType;
    data: StructuredDataTemplateList;
};

export function StructuredData({ id, type, data }: StructuredDataProps) {
    // @TODO - investigate - why it doesnt show data in Head/Script?
    return (
        // <Head> <Script>
        <script
            key={`structured-data-${id}`}
            id={id ? `structured-data-${id}` : undefined}
            type="application/ld+json"
            dangerouslySetInnerHTML={addJSONLDData({ type, data })}
        />
        // </Head>
    );
}

StructuredData.type = StructuredDataType;
