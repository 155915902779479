import * as React from 'react';
import cx from 'classnames';
import { Header1 } from 'components/typography';
import HeroImage from 'components/hero-image/hero-image';
import useMediaQuery from 'lib/use-media-query';
import { useAmp } from 'next/amp';
import { HeroBlockContext } from 'components/success-stories/hero-block/hero-block.context';

enum HeroBlockType {
    video = 'video',
    image = 'image',
}

enum HeroBlockMode {
    NONE = 'NONE',
    BFF = 'BFF',
}

enum HeroBlockContentVerticalAlignment {
    top = 'top',
    center = 'center',
    bottom = 'bottom',
}

enum HeroBlockContentHorizontalAlignment {
    left = 'left',
    center = 'center',
    right = 'right',
}

type HeroBlockProps = {
    alt?: string | undefined;
    type?: HeroBlockType;
    title?: string;
    url?: string;
    children?: React.ReactNode;
    fullWidth?: boolean;
    contentVerticalPosition?: HeroBlockContentVerticalAlignment;
    contentHorizontalPosition?: HeroBlockContentHorizontalAlignment;
    aspectRatio?: number;
    priority?: boolean;
    height?: number;
    width?: number;
    hasFrame?: boolean;
    mode?: HeroBlockMode;
};

function HeroBlock({
    alt,
    type = HeroBlockType.image,
    title,
    url,
    children,
    fullWidth,
    contentVerticalPosition,
    contentHorizontalPosition,
    aspectRatio = 1,
    priority,
    height,
    width,
    hasFrame,
    mode,
}: HeroBlockProps) {
    const frameRef = React.useRef<HTMLDivElement>(null);
    const { setOpticCompensation } = React.useContext(HeroBlockContext);

    React.useEffect(() => {
        function handleResize() {
            if (!frameRef.current) {
                return;
            }

            const widthBenchmarkElementWidth =
                frameRef.current?.offsetWidth > 940
                    ? global.document.body.getBoundingClientRect().width
                    : frameRef.current?.offsetWidth;
            if (widthBenchmarkElementWidth) {
                setOpticCompensation([
                    parseFloat((widthBenchmarkElementWidth / 1320).toFixed(2)),
                    parseFloat((frameRef.current?.offsetHeight / 742).toFixed(2)),
                ]);
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setOpticCompensation]);

    const isAmp = useAmp();
    const isBreakpoint = useMediaQuery();
    const className = {
        block: cx({
            'hero-block': true,
            'hero-block--full-width': fullWidth,
            'hero-block--has-frame': hasFrame,
            'hero-block--bff': mode && mode === HeroBlockMode.BFF,
        }),
        inner: cx({
            'hero-block__inner': true,
            'hero-block__inner--center':
                contentVerticalPosition === HeroBlockContentVerticalAlignment.center &&
                contentHorizontalPosition === HeroBlockContentHorizontalAlignment.center,
        }),
        media: cx({
            'hero-block__media-bg': true,
        }),
        video: cx({
            'hero-block__video-bg': true,
        }),
        title: cx({
            'hero-block__title': true,
        }),
    };
    const heroBlockChildrenElement =
        children || title ? (
            <div className={className.inner}>
                {children}

                {title ? (
                    <div className={className.title}>
                        <Header1>{title}</Header1>
                    </div>
                ) : null}
            </div>
        ) : null;
    const HeroBlockElement = (
        <div className={className.block} ref={frameRef}>
            {type === HeroBlockType.video && url ? (
                <video className={className.video} autoPlay={true} muted={true} loop={true}>
                    <source src={url} type="video/mp4" />
                </video>
            ) : null}

            {type === HeroBlockType.image && url ? (
                <div className={className.media}>
                    <HeroImage
                        width={width}
                        height={height}
                        alt={alt || title}
                        priority={priority}
                        src={url}
                        aspectRatio={aspectRatio || (isBreakpoint ? 375 / 454 : 1440 / 700)}
                    />
                </div>
            ) : null}

            {isAmp ? null : heroBlockChildrenElement}
        </div>
    );

    return HeroBlockElement;
}

export default HeroBlock;

HeroBlock.type = HeroBlockType;
HeroBlock.contentVerticalAlignment = HeroBlockContentVerticalAlignment;
HeroBlock.contentHorizontalAlignment = HeroBlockContentHorizontalAlignment;
HeroBlock.mode = HeroBlockMode;
