import { SupportedLanguage } from 'lib/lexemes/supported-languages';
import { getArticlePageByUrl, getCategoryPageByUrl, getIndexPage } from 'lib/api';

export async function getArticlePageProps(url: string, lang: SupportedLanguage) {
    const {
        id,
        title,
        description,
        blocks,
        imageUrl,
        imageAlt,
        imageHeight,
        imageWidth,
        seoTitle,
        seoDescription,
        metaKeywords,
        metaRobotsNoindex,
        metaRobotsNofollow,
        opengraphPublishedTime,
        opengraphModifiedTime,
        categories,
        // @ts-ignore
        footerCtaText,
        // @ts-ignore
        footerCtaUrl,
        pageCategoriesList,
    } = await getArticlePageByUrl(url, lang);

    return {
        id,
        lang,
        title,
        description,
        blocks,
        imageUrl,
        imageAlt,
        imageHeight,
        imageWidth,
        seoTitle,
        seoDescription,
        metaKeywords,
        metaRobotsNoindex,
        metaRobotsNofollow,
        opengraphPublishedTime,
        opengraphModifiedTime,
        categories,
        pageCategoriesList,
        footerCtaText,
        footerCtaUrl,
    };
}

export async function getCategoryPageProps(
    url: string,
    lang: SupportedLanguage
    // pathUrl?: string
) {
    const {
        title,
        description,
        blocks,
        imageUrl,
        imageAlt,
        imageHeight,
        imageWidth,
        seoTitle,
        seoDescription,
        metaKeywords,
        metaRobotsNoindex,
        metaRobotsNofollow,
        opengraphPublishedTime,
        opengraphModifiedTime,
        pageCategoriesList,
    } = await getCategoryPageByUrl(url, lang);

    return {
        lang,
        title,
        description,
        blocks,
        imageUrl,
        imageAlt,
        imageHeight,
        imageWidth,
        seoTitle,
        seoDescription,
        metaKeywords,
        metaRobotsNoindex,
        metaRobotsNofollow,
        opengraphPublishedTime,
        opengraphModifiedTime,
        pageCategoriesList,
    };
}

export async function getIndexPageProps(lang: SupportedLanguage) {
    const {
        title,
        description,
        blocks,
        imageUrl,
        imageAlt,
        imageHeight,
        imageWidth,
        seoTitle,
        seoDescription,
        metaKeywords,
        metaRobotsNoindex,
        metaRobotsNofollow,
        opengraphPublishedTime,
        opengraphModifiedTime,
        pageCategoriesList,
    } = await getIndexPage(lang);

    return {
        lang,
        title,
        description,
        blocks,
        imageUrl,
        imageAlt,
        imageHeight,
        imageWidth,
        seoTitle,
        seoDescription,
        metaKeywords,
        metaRobotsNoindex,
        metaRobotsNofollow,
        opengraphPublishedTime,
        opengraphModifiedTime,
        pageCategoriesList,
    };
}
