import * as React from 'react';
import { BlockProps } from '../index.types';

export function ListItem({ block: { innerHTML } }: BlockProps) {
    // @TODO: confirm that the issue with hydration is due to innher html - perhaps we need to remove external wrapper?
    return innerHTML ? (
        <li
            className="list-item__inner formatted-text"
            dangerouslySetInnerHTML={{ __html: innerHTML }}
        />
    ) : null;
}
