import * as React from 'react';
import cx from 'classnames';

type SuccessStoriesPropsType = {
    children?: React.ReactNode | React.ReactNode[];
    size?: 'md' | 'sm' | 'lg';
    key?: string;
    limit?: number;
};

const mapItemIndexToLimit = (item: React.ReactNode, index: number, limit: number, key: string) =>
    index < limit ? (
        <div className="success-stories__item" key={`success-stories-${key}-${index}`}>
            {item}
        </div>
    ) : null;

function SuccessStories(props: SuccessStoriesPropsType) {
    const { limit = 3, key = '' } = props;
    const className = cx({
        'success-stories': true,
        [`success-stories--${props.size}`]: props.size,
    });

    return (
        <div className={className}>
            {React.Children.toArray(props.children)
                .filter(Boolean)
                .map((item, index) => mapItemIndexToLimit(item, index, limit, key))}
        </div>
    );
}

export default SuccessStories;
