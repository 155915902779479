import * as React from 'react';
import {
    PageCategoriesList,
    setMoreCategoryPostsList,
} from 'components/more-category-posts/more-category-posts';

export type PageArchiveCategoryListProps = {
    pageCategoriesList: PageCategoriesList;
    backgroundColor?: string;
    filter: string;
    breadcrumbs?: React.ReactNode;
    titleUrl?: string;
};

export function PageArchiveCategoryList({
    pageCategoriesList,
    filter,
    backgroundColor,
    breadcrumbs,
    titleUrl,
}: PageArchiveCategoryListProps) {
    return (
        <React.Fragment>
            {pageCategoriesList?.edges
                ?.filter((category) => filter === category?.node?.name)
                ?.sort(
                    (edgePrev, edgeNext) =>
                        edgePrev?.node?.postCategoryFieldGroup.order -
                        edgeNext?.node?.postCategoryFieldGroup.order
                )
                .filter((edgeItem) => edgeItem.node?.postCategoryFieldGroup.showonmainpage)
                .map((category, index) =>
                    setMoreCategoryPostsList({
                        edge: category,
                        index,
                        backgroundColor,
                        breadcrumbs,
                        isCategoryPage: true,
                        titleUrl,
                    })
                )}
        </React.Fragment>
    );
}
