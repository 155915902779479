import * as React from 'react';
import PageSection from 'components/page/page-section';
import HeroImage from 'components/hero-image/hero-image';

type ImageProps = {
    src?: string;
    originalHeight?: number;
    originalWidth?: number;
    srcset?: string;
    alt?: string | null;
    height?: number;
    width?: number;
};

export function Image(props: ImageProps) {
    return props.src ? (
        <PageSection inner={false} theme={PageSection.Theme.NONE} extraClass="page-section--image">
            <HeroImage
                height={props.height}
                width={props.height}
                src={props.src}
                alt={props.alt || undefined}
                aspectRatio={props.height && props.width ? props.width / props.height : 1}
            />
        </PageSection>
    ) : null;
}
