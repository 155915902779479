import * as React from 'react';
import Page from 'components/page/page';
import { IconDefs } from 'components/icon/icon';
import PageSeo, { PageSEOPropsType } from 'components/page-seo/page-seo';
import { PageHeader } from 'components/page-header/page-header';
import PageMobilePanel from 'components/page-mobile-panel/page-mobile-panel';
import PageFooter from 'components/page-footer/page-footer-static';
import PageCookie from 'components/page-cookie/page-cookie';
import { handleCookieConsent, onUsePrivacyManagerHandler } from 'lib/cookie-consent';
import { Maybe, RootQueryToCategoryConnection, Scalars } from 'interfaces/microsite-cms';
import { useRouter } from 'next/router';
import { baseUrl } from 'lib/base-url';
import { PageCategoriesList } from 'components/more-category-posts/more-category-posts';
import { basePathIndexUrl } from 'lib/schemes';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';
import PageTemplateContent, { PageTemplateContentType } from './page-template-content';

// const keywords = undefined;

type PageTemplateProps = {
    id?: Scalars['Int'];
    lang: SupportedLanguage;
    type: PageTemplateContentType;
    intro?: React.ReactNode;
    categories?: Maybe<RootQueryToCategoryConnection>;
    pageCategoriesList?: PageCategoriesList;
    children?: React.ReactNode;
    modal?: React.ReactNode;
    title?: string;
    browserTab?: string;
    seoTitle?: string;
    seoDescription?: string;
    seoUrl?: string;
    openTitle?: string;
    openDescription?: string;
    datePublished?: string;
    dateModified?: string;
    // @TODO: add support in CMS
    metaKeywords?: string | null;
    metaRobotsNoindex?: string | null;
    metaRobotsNofollow?: string | null;
    opengraphPublishedTime?: string | null;
    opengraphModifiedTime?: string | null;
    openImageUrl?: string | null;
    openImageAlt?: string | null;
    heroImageUrl?: string | null;
    heroImageAlt?: string | null;
    heroImageHeight?: string | null;
    heroImageWidth?: string | null;
    openSiteName?: string;
    hasFooterCta?: boolean;
    footerTitleText?: Maybe<string> | undefined;
    hasFooterCtaButton?: boolean;
    ctaButtonText?: Maybe<string> | undefined;
    ctaButtonUrl?: Maybe<string> | undefined;
    ctaButtonTargetNewWindow?: boolean;
    onPageClick?: () => void;
    dynamicFooter?: boolean;
    geoIpCountry?: string | null;
    pageUrl?: string;
} & PageSEOPropsType;

function PageTemplate(props: PageTemplateProps) {
    const { onPageClick, geoIpCountry, type = PageTemplateContentType.Article } = props;
    const router = useRouter();
    const url = `${baseUrl}${router.basePath}${router.asPath}`;

    React.useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            const listenerId = __tcfapi?.('addEventListener', 2, handleCookieConsent);

            return () => {
                if (listenerId) {
                    __tcfapi?.('removeEentListener', 2, null, listenerId);
                }
            };
        } else {
            return;
        }
    }, []);
    React.useEffect(onUsePrivacyManagerHandler, []);

    return (
        <>
            <PageSeo
                metaRobotsNofollow={props.metaRobotsNofollow}
                metaRobotsNoindex={props.metaRobotsNoindex}
                lang={props.lang}
                browserTab={props.browserTab}
                seoDescription={props.seoDescription}
                seoUrl={url}
                openImageUrl={props.openImageUrl || props.heroImageUrl}
                showAmpUrl={type === PageTemplateContentType.Article}
            />

            <Page
                header={
                    <PageHeader
                        extraClass={
                            type === PageTemplateContentType.Index ? 'is-transparent' : undefined
                        }
                        logoUrl={
                            type === PageTemplateContentType.Index
                                ? 'https://bumble.com'
                                : basePathIndexUrl
                        }
                    />
                }
                content={<PageTemplateContent articleUrl={url} {...props} />}
                footer={<PageFooter geoIpCountry={geoIpCountry} />}
                cookieNotification={<PageCookie />}
                mobilePanel={<PageMobilePanel />}
                onPageClick={onPageClick}
            />

            {<IconDefs />}
        </>
    );
}
PageTemplate.type = PageTemplateContentType;

export default PageTemplate;
