import * as React from 'react';
import cx from 'classnames';

enum FlexImageListGrid {
    NONE = 'NONE',
    THREE = 'THREE',
    FOUR = 'FOUR',
}

const mapGridToClasses = {
    [FlexImageListGrid.NONE]: '',
    [FlexImageListGrid.THREE]: 'flexible-image-list--three-items',
    [FlexImageListGrid.FOUR]: 'flexible-image-list--four-items',
};

type FlexImageListPropsType = {
    children?: React.ReactNode | React.ReactNode[];
    size?: 'md' | 'sm' | 'lg';
    grid?: FlexImageListGrid;
    key?: string;
};

const mapItemIndexToLimit = (item: React.ReactNode, index: number, key: string) => (
    <div className="flexible-image-list__item" key={`flexible-image-list-${key}-${index}`}>
        {item}
    </div>
);
// flexible-image-list--three-items
function FlexImageList(props: FlexImageListPropsType) {
    const { key = '', grid = FlexImageListGrid.NONE } = props;
    const className = cx({
        'flexible-image-list': true,
        [mapGridToClasses[grid]]: true,
        [`flexible-image-list--${props.size}`]: props.size,
    });

    return (
        <div className={className}>
            {React.Children.toArray(props.children)
                .filter(Boolean)
                .map((item, index) => mapItemIndexToLimit(item, index, key))}
        </div>
    );
}

export default FlexImageList;
FlexImageList.Grid = FlexImageListGrid;
