import * as React from 'react';
import { NextSeo } from 'next-seo';
import lexemes from 'components/lexemes/sharing-assets/lexemes';
import { baseUrl } from 'lib/base-url';
import { Router, useRouter } from 'next/router';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';
import { cleanUrlFromParams } from 'lib/set-url-params';
import { useAmp } from 'next/amp';
import { basePathIndexUrl } from 'lib/schemes';
import Head from 'next/head';
const PAGE_PARTNER_NAME = 'Bumble';

/**
 * Returns "alternate" languages for SEO
 */
function getLanguageAlternates(_router: Router) {
    // @TODO add alt
    // const lang = 'en-us';

    // return [
    //     {
    //         hrefLang: lang,
    //         href: `https://bumble.com${router.basePath}/${router.query.lang}/${router.query.slug}`,
    //     },
    // ];
    return [];
}

const setAbsPathForImageUrl = (imageUrl: string) => {
    return imageUrl.replace(
        'http://cdn-internal:8081/bumble-bff-friendship-production/',
        `${baseUrl}/bff/friendship/cdn-image/`
    );
};
const SEO = {
    get TAB_TITLE() {
        return 'Bumble Seasonal Content Hub | Bumble';
    },
    get NAME() {
        return 'Bumble Seasonal Content Hub | Bumble';
    },
    get TITLE() {
        return 'Bumble Seasonal Content Hub | Bumble';
    },
    get DESCRIPTION() {
        return 'Looking to submit your own Bumble success story or want to see our Seasonal Content Hub to make you fall in love all over again? See what it started with here.';
    },
    URL: 'https://bumble.com/bff/friendship',
    IMAGE_URL: 'https://bumble.com/bff/assets/social-sharing.jpg',
    // IMAGE_URL: setAbsPathForImageUrl('/social-sharing.jpg'),
    get IMAGE_ALT() {
        return lexemes.lxBumbleHomepageTabTitle({ page_partner_name: PAGE_PARTNER_NAME });
    },
};

export type PageSEOPropsType = {
    lang: SupportedLanguage;
    browserTab?: string;
    seoTitle?: string;
    seoDescription?: string;
    seoUrl?: string;
    openTitle?: string;
    openDescription?: string;
    openImageUrl?: string | null;
    openImageAlt?: string | null;
    openImageHeight?: string | null;
    openImageWidth?: string | null;
    openSiteName?: string;
    metaRobotsNoindex?: string | null;
    metaRobotsNofollow?: string | null;
    showAmpUrl?: boolean;
};

const PageSEO = (props: PageSEOPropsType) => {
    const router = useRouter() as Router;
    const canonicalUrl = cleanUrlFromParams(props.seoUrl || SEO.URL).replace(/\/$/, '');
    const isAmp = useAmp();
    const ampCanonicalUrl = isAmp
        ? canonicalUrl
        : canonicalUrl.replace(basePathIndexUrl, `${basePathIndexUrl}/amp`);
    const imageUrl = props.openImageUrl ? setAbsPathForImageUrl(props.openImageUrl) : SEO.IMAGE_URL;
    const imageAlt = props.openImageAlt || props.browserTab || SEO.IMAGE_ALT;
    const pageTitle = props.browserTab || SEO.TAB_TITLE;
    const pageDescriotion = props.seoDescription || SEO.DESCRIPTION;
    const siteName = props.openSiteName || props.browserTab || SEO.NAME;

    const ampLink = props.showAmpUrl ? <link rel="amphtml" href={ampCanonicalUrl} /> : null;

    return (
        <>
            <Head>{isAmp ? null : ampLink}</Head>
            <NextSeo
                noindex={props.metaRobotsNoindex === 'noindex'}
                nofollow={props.metaRobotsNofollow === 'nofollow'}
                title={pageTitle}
                description={pageDescriotion}
                canonical={isAmp ? ampCanonicalUrl : canonicalUrl}
                openGraph={{
                    url: canonicalUrl,
                    locale: props.lang,
                    title: props.openTitle ? props.openTitle : pageTitle,
                    description: props.openDescription ? props.openDescription : pageDescriotion,
                    images: [
                        {
                            url: imageUrl,
                            alt: imageAlt,
                            width: 1200,
                            height: 630,
                        },
                    ],
                    site_name: siteName,
                }}
                twitter={{
                    handle: '@bumble',
                    site: '@bumble',
                    cardType: 'summary_large_image',
                }}
                languageAlternates={getLanguageAlternates(router)}
                facebook={{
                    appId: '428250913904849',
                }}
                additionalMetaTags={[
                    {
                        name: 'apple-itunes-app',
                        content: 'app-id=930441707',
                    },
                    {
                        property: 'p:domain_verify',
                        content: '26cd87d4b7db08a05bdfa2219d3671b6',
                    },
                    {
                        property: 'referrer',
                        content: 'origin-when-cross-origin',
                    },
                    {
                        property: 'twitter:app:name:iphone',
                        content: 'Bumble - Changing the rules of the game.',
                    },
                    {
                        property: 'twitter:app:id:iphone',
                        content: '930441707',
                    },
                ]}
            />
        </>
    );
};

export default PageSEO;
