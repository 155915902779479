import { clickEventFromElement } from 'components/google-analytics/google-analytics';
import { BFF_HELP_CENTER_LINKS, BFF_MENU_LINKS, downloadUrl } from 'lib/constants';
import * as React from 'react';

type PageFooterStaticProps = {
    geoIpCountry?: string | null;
};

const PageFooterStatic: React.FC<PageFooterStaticProps> = (props: PageFooterStaticProps) => {
    const { geoIpCountry } = props;

    return (
        <div id="footer" className="wrapper black-bg white">
            <div className="content">
                <div className="flex space-between v-center md:h-center">
                    <div>
                        <img
                            className="logo"
                            style={{ height: '36px', width: '36px' }}
                            src="/bff/friendship/icons/bumble_yellow.svg"
                            alt="Bumble Logo"
                        />
                        <a
                            className="download white weight-medium-italic"
                            href={downloadUrl}
                            target="_blank"
                            rel="noreferrer"
                            data-category="bumble-friendship-hub Navigation"
                            data-action="Button Click Footer"
                            data-label="Download app"
                            onClick={(event) => clickEventFromElement(event)}
                        >
                            Download Now
                        </a>
                    </div>
                    <a
                        className="link"
                        href="http://apple.co/ASA2021-Winners"
                        target="_blank"
                        rel="noreferrer"
                        title="2021: Trend of the year"
                    >
                        <img
                            className="trend-award md:hide"
                            src="/bff/friendship/icons/2021_trend_of_the_year_white.svg"
                            alt="2021: Trend of the year"
                        />
                    </a>
                </div>
                <div className="links flex">
                    <div className="section w-1-2 md:w-1-1">
                        <div className="flex wrap space-between">
                            <div style={{ paddingRight: 24 }}>
                                <p className="yellow weight-bold" style={{ paddingBottom: 12 }}>
                                    The App
                                </p>
                                <a
                                    className="white weight-book"
                                    href="https://bumble.com/en/date"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Bumble Date
                                </a>
                                <a
                                    className="white weight-book"
                                    href="https://bumble.com/en/bff"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Bumble BFF
                                </a>

                                <a
                                    className="white weight-book"
                                    href="https://bumble.com/en/bizz"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Bumble Bizz
                                </a>
                            </div>
                            <div className="md:no-padding" style={{ paddingRight: 12 }}>
                                <p className="yellow weight-bold" style={{ paddingBottom: 12 }}>
                                    The Company
                                </p>
                                <a
                                    className="white weight-book"
                                    href={BFF_HELP_CENTER_LINKS.INDEX}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Help Centre
                                </a>
                                <a
                                    className="white weight-book"
                                    href="https://bumble.events/bumbleirlthemenew"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Events
                                </a>

                                <a
                                    className="white weight-book"
                                    href={BFF_MENU_LINKS.GUIDELINES}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Guidelines
                                </a>
                                <a
                                    className="white weight-book"
                                    href={BFF_MENU_LINKS.PRIVACY}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </a>
                                <a
                                    className="white weight-book"
                                    href={BFF_MENU_LINKS.TERMS}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms &amp; Conditions
                                </a>
                                {geoIpCountry !== 'us' ? (
                                    <a
                                        className="white weight-book"
                                        href="https://bumble.com/cookie-policy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Cookie Policy
                                    </a>
                                ) : (
                                    <a
                                        className="white weight-book js-ccpa-privacy"
                                        href="/"
                                        rel="noreferrer"
                                    >
                                        Do not sell or share my personal information
                                    </a>
                                )}
                                <a
                                    className="white weight-book"
                                    href={BFF_HELP_CENTER_LINKS.INDEX}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="section w-1-2 md:w-1-1">
                        <div className="flex wrap space-between">
                            <div className="md:no-padding" style={{ paddingRight: 12 }}>
                                <p className="yellow weight-bold">The Buzz</p>
                                <a
                                    className="white weight-book"
                                    href="https://bumble.com/en/the-buzz/love"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Love
                                </a>
                                <a
                                    className="white weight-book"
                                    href="https://bumble.com/en/the-buzz/friendship"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Friendship
                                </a>
                                <a
                                    className="white weight-book"
                                    href="https://bumble.com/en/the-buzz/career"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Career
                                </a>
                                <a
                                    className="white weight-book"
                                    href="https://bumble.com/en/the-buzz/wellness"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Wellness
                                </a>
                                <a
                                    className="white weight-book"
                                    href="https://bumble.com/en/the-buzz/how-to"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    How-to
                                </a>
                                <a
                                    className="white weight-book"
                                    href="https://bumble.com/en/the-buzz/events"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Events
                                </a>
                                <a
                                    className="white weight-book"
                                    href="https://bumble.com/success-stories"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Submit a story
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex space-between md:column">
                    <div className="md:h-center block md:center-align">
                        <img
                            className="trend-award lg:hide block"
                            src="/bff/friendship/icons/2021_trend_of_the_year_white.svg"
                            alt="2021: Trend of the year"
                        />
                        <img
                            style={{ height: '12px', width: '182px' }}
                            className="make-the-first-move"
                            src="/bff/friendship/icons/make_the_first_move_white.svg"
                            alt="Make the first move"
                        />
                        <p className="copyright">©2022 Bumble. All Rights Reserved</p>
                    </div>
                    <div className="flex social-icons md:h-center">
                        <a href="https://www.tiktok.com/@bumble" target="_blank" rel="noreferrer">
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/tiktok.svg?v=1"
                                alt="TikTok"
                            />
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/tiktok_hover.svg?v=1"
                                alt="TikTok"
                            />
                        </a>
                        <a href="https://twitter.com/bumble" target="_blank" rel="noreferrer">
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/twitter.svg?v=1"
                                alt="Twitter"
                            />
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/twitter_hover.svg?v=1"
                                alt="Twitter"
                            />
                        </a>
                        <a href="https://instagram.com/bumble" target="_blank" rel="noreferrer">
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/instagram.svg?v=1"
                                alt="Instagram"
                            />
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/instagram_hover.svg?v=1"
                                alt="Instagram"
                            />
                        </a>
                        <a
                            href="https://www.facebook.com/bumbleapp"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/facebook.svg?v=1"
                                alt="Facebook"
                            />
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/facebook_hover.svg?v=1"
                                alt="Facebook"
                            />
                        </a>
                        <a href="https://www.youtube.com/c/bumble" target="_blank" rel="noreferrer">
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/youtube.svg?v=1"
                                alt="Youtube"
                            />
                            <img
                                style={{ height: '36px', width: '36px' }}
                                src="/bff/friendship/icons/youtube_hover.svg?v=1"
                                alt="Youtube"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageFooterStatic;
