import * as React from 'react';
import CtaBox, { CtaBoxAlign } from 'components/cta-box/cta-box';
import CtaBoxMedia, { CtaBoxMediaAlign } from 'components/cta-box/cta-box-media';
import CtaBoxTitle, { CtaBoxTitleAlign } from 'components/cta-box/cta-box-title';
import Header1 from 'components/typography/header-1';
import Header2 from 'components/typography/header-2';
import Header3 from 'components/typography/header-3';
import P1 from 'components/typography/p-1';
import Caption from 'components/typography/caption';

export enum TextSectionTitleStyle {
    'H1',
    'H2',
    'H3',
}

type TextSectionTitleStyleType = TextSectionTitleStyle;

type TextSectionPropsType = {
    align?: CtaBoxAlign;
    caption?: string;
    title?: string;
    media?: React.ReactElement;
    mediaAlign?: CtaBoxMediaAlign;
    text?: string;
    extraText?: string;
    titleStyle?: TextSectionTitleStyleType;
    titleStyles?: any;
    titleCenter?: boolean;
    textCenter?: boolean;
    textMarginTop?: number;
};

function TextSection({
    align,
    caption,
    title,
    media,
    mediaAlign,
    text,
    textCenter,
    textMarginTop,
    extraText,
    titleStyle = TextSectionTitleStyle.H2,
    titleStyles,
    titleCenter,
}: TextSectionPropsType) {
    // @todo: I Missed it during review. will need to cover with VRT and refactor
    let marginTop = caption || media || title ? 25 : undefined;

    if (textMarginTop) {
        marginTop = textMarginTop;
    }

    return (
        <CtaBox align={align}>
            <>
                {caption ? (
                    <CtaBoxTitle>
                        <Caption html={caption} />
                    </CtaBoxTitle>
                ) : null}
            </>

            <>{media ? <CtaBoxMedia align={mediaAlign}>{media}</CtaBoxMedia> : null}</>

            <>
                {title ? (
                    <CtaBoxTitle align={titleCenter ? CtaBoxTitleAlign.CENTERED : undefined}>
                        <>
                            {titleStyle === TextSectionTitleStyle.H1 ? (
                                <Header1 html={title} style={titleStyles} />
                            ) : null}
                            {titleStyle === TextSectionTitleStyle.H2 ? (
                                <Header2 html={title} style={titleStyles} />
                            ) : null}
                            {titleStyle === TextSectionTitleStyle.H3 ? (
                                <Header3 html={title} style={titleStyles} />
                            ) : null}
                        </>
                    </CtaBoxTitle>
                ) : null}
            </>

            <>
                {/* TODO: review approach with resetting text align and setting margins directly in JS */}
                {text || extraText ? (
                    <div style={{ textAlign: 'initial' }}>
                        <CtaBoxTitle
                            marginTop={marginTop}
                            align={textCenter ? CtaBoxTitleAlign.CENTERED : undefined}
                        >
                            <>{text ? <P1 html={text} /> : null}</>
                            <>{extraText ? <P1 html={extraText} /> : null}</>
                        </CtaBoxTitle>
                    </div>
                ) : null}
            </>
        </CtaBox>
    );
}

export default TextSection;

TextSection.titleStyle = TextSectionTitleStyle;

export { CtaBoxAlign as TextSectionAlign, CtaBoxMediaAlign as TextSectionMediaAlign };
