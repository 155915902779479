import React, { useCallback, useState } from 'react';
import MobilePanel from 'components/mobile-panel/mobile-panel';
import MobileMenu from 'components/mobile-menu/mobile-menu';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import Icon from 'components/icon/icon';
import { clickEventFromElement } from 'components/google-analytics/google-analytics';
import { actionSignInUrl, downloadUrl } from 'lib/constants';

export default function PageMobilePanel() {
    const [isActive, setIsActive] = useState(false);

    const closePanel = useCallback(() => {
        global.document.body.classList.toggle('is-opened-mobile-nav', false);
        setIsActive(false);
    }, []);

    const mobileMenuItemsTeamBumble = [
        {
            title: 'Sign in',
            onClickMenuLink: (event: React.MouseEvent<HTMLAnchorElement>) => {
                clickEventFromElement(event);
            },
            url: actionSignInUrl,
            seo: {
                name: 'click',
                category: 'bumble-friendship-hub Navigation',
                action: 'Sign in',
                label: 'Page mobile menu',
            },
        },
        {
            title: 'Download the app',
            onClickMenuLink: (event: React.MouseEvent<HTMLAnchorElement>) => {
                clickEventFromElement(event);
            },
            url: downloadUrl,
            seo: {
                name: 'click',
                category: 'bumble-friendship-hub Navigation',
                action: 'Download form',
                label: 'Page mobile menu',
            },
        },
    ];

    return (
        <MobilePanel
            logo={<Logo color="inherit" isOld={false} isNew={true} />}
            isActive={isActive}
            closePanel={closePanel}
            closeAction={
                <Button
                    isCircle={true}
                    jsClass="js-close-menu"
                    icon={<Icon size="md" name={Icon.Name.Close} />}
                    onClick={closePanel}
                />
            }
            menuList={[
                ...mobileMenuItemsTeamBumble.map((menuItem, index) => (
                    <MobileMenu {...menuItem} key={`item-${index}`} />
                )),
            ]}
        />
    );
}
