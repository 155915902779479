import { log } from 'lib/log';
import axios, { AxiosResponse } from 'axios';
import https from 'https';
import http, { IncomingHttpHeaders } from 'http';

// 10 seconds
const DEFAULT_TIMEOUT = 1000 * 10;

type Headers = IncomingHttpHeaders;

async function fetchData(apiUrl: string, headersFromRequest?: Headers) {
    const headers: Headers = {
        ...headersFromRequest,
        host: 'bumble.com',
    };

    const ref = headersFromRequest?.Referer || headersFromRequest?.referer;

    /**
     * we only send requests that has correct refferer
     */
    if (ref?.includes('google.com')) {
        const response: void | AxiosResponse<{ data: number }> = await axios
            .get(apiUrl, {
                headers,
                timeout: DEFAULT_TIMEOUT,
                httpAgent: new http.Agent({
                    keepAlive: true,
                    keepAliveMsecs: 1000 * 30,
                    maxFreeSockets: 50,
                    timeout: DEFAULT_TIMEOUT,
                }),
                httpsAgent: new https.Agent({
                    keepAlive: true,
                    keepAliveMsecs: 1000 * 30,
                    maxFreeSockets: 50,
                    timeout: DEFAULT_TIMEOUT,
                }),
            })
            .catch((error) => {
                log(`SEOClick Event data error => ${JSON.stringify(error)}`);
            });

        const data = await response;

        log(`SEOClick Event data => ${JSON.stringify(data?.data)}`);

        return 200;
    }

    return 200;
}

async function getData(path: string, host?: string, headers?: Headers) {
    // HACK: axios-cached-dns-resolve overrides Host header inside axios interceptor
    // so we need such workaround here, where we can resolve IP address and part of interceptor is skipped
    // const address = await getAddress(host);
    // const result = await fetchData(`https://${address}${path}`, headers);
    const result = await fetchData(`https://${host}${path}`, headers);

    return result;
}

const setDataType = (dataType: string, language: string) =>
    language ? `/${language}/${dataType}` : `/${dataType}`;

export async function getDataFactory(type: string, lang: string, host?: string, headers?: Headers) {
    const dataType = setDataType(type, lang);
    const data = await getData(dataType, host, headers);

    return data;
}
