import * as React from 'react';
import Header from 'components/header/header';
import Icon from 'components/icon/icon';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import { Lexeme, useLocalization } from '@bumble/localization/components';
import { headerMenuItems, actionSignInUrl, downloadUrl } from 'lib/constants';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';

import { clickEventFromElement } from 'components/google-analytics/google-analytics';
import HeaderMenu from 'components/header/header-menu';

type Props = {
    menuAction?: boolean;
    extraClass?: string;
    logoUrl?: string;
};

export function PageHeader({ menuAction = true, extraClass, logoUrl }: Props) {
    const isBreakpoint = useMediaQuery(MediaQueryWidth['tablet-md']);
    const isBreakpointDesktop = useMediaQuery(MediaQueryWidth['desktop-md']);
    const onOpenMenu = React.useCallback(() => {
        global.document.body.classList.toggle('is-opened-mobile-nav');
        // This is super crappy but need to leave it as is for now
        const selector = global.document.querySelector('.js-mobile-navigation');

        if (selector) {
            selector.classList.toggle('is-active');
        }
    }, []);

    const Localization = useLocalization();

    return (
        <Header
            extraClass={isBreakpoint ? extraClass : `has-actions-v-aligned ${extraClass || ''}`}
            logo={<Logo />}
            // Header button specifically links to the root domain
            logoUrl={logoUrl}
            headerMenu={<HeaderMenu items={headerMenuItems} />}
            actionMobilePanel={
                isBreakpoint && menuAction ? (
                    //@todo - https://jira.badoojira.com/browse/LOC-5433
                    <Button
                        labelText="Open Menu"
                        isCircle={true}
                        icon={<Icon size="md" name={Icon.Name.Menu} color="primary-black" />}
                        onClick={onOpenMenu}
                    />
                ) : null
            }
            actionAppDownload={
                isBreakpointDesktop ? null : (
                    <Button
                        labelText={Localization.get(
                            'bma_client@dialog.photo_verification.not_access.download_app'
                        )}
                        color={Button.color.Primary}
                        designType={Button.designType.FILLED}
                        extraClass="button--footer-cta button--color-primary"
                        href={downloadUrl}
                        text={
                            <Lexeme path="bma_client@dialog.photo_verification.not_access.download_app" />
                        }
                        seo={{
                            category: 'bumble-friendship-hub Navigation',
                            action: 'Button Click Header',
                            label: 'Download app',
                        }}
                        onClick={(event) => clickEventFromElement(event)}
                    />
                )
            }
            actionSignIn={
                isBreakpointDesktop ? null : (
                    <Button
                        labelText={Localization.get('bma_client@pages.login-password.submit')}
                        color={Button.color.White}
                        extraClass="button--footer-cta button--color-primary"
                        text={<Lexeme path="bma_client@pages.login-password.submit" />}
                        href={actionSignInUrl}
                        seo={{
                            category: 'bumble-friendship-hub Navigation',
                            action: 'Button Click Header',
                            label: 'Sign in',
                        }}
                        onClick={(event) => clickEventFromElement(event)}
                    />
                )
            }
        />
    );
}
