import * as React from 'react';
import Image, { ImageProps } from 'next/image';
import { log } from 'lib/log';
// import { baseUrl } from 'lib/base-url';
import { updateImagePaths } from 'lib/updateImagePaths';

const ImageContainer = (props: ImageProps) => {
    const customSrc = typeof props.src === 'string' ? updateImagePaths(props.src) : props.src;
    const layout = props.layout || 'fill';

    log(`customSrc output ==> ${customSrc}`);

    return (
        <Image
            priority={props.priority}
            layout={layout}
            {...props}
            width={layout !== 'fill' ? props.width : undefined}
            height={layout !== 'fill' ? props.height : undefined}
        />
    );
};

export default ImageContainer;
