import * as React from 'react';
import Link from 'components/link/link';
import Icon from 'components/icon/icon';
import { P1 } from 'components/typography';
import { baseUrl } from 'lib/base-url';
import { basePath } from 'lib/base-path';
import { StructuredData } from 'components/structured-data';
import { Maybe, RootQueryToCategoryConnection } from 'interfaces/microsite-cms';

export function setBreadCrumbs({
    categories,
    name,
    slug,
}: {
    categories?: Maybe<RootQueryToCategoryConnection>;
    name?: string;
    slug?: string;
}) {
    const breadcrumbsElementList = [
        { name: `Friendship Guides`, slug: '/bff/friendship/' },
        categories?.edges ? categories?.edges[0]?.node : null,
        { name, slug },
    ]
        .filter((breadcrumb) => breadcrumb?.name)
        .map((item, index) => {
            if (!item?.slug?.startsWith('/bff/friendship/')) {
                //@ts-ignore
                item.slug = `${basePath}${item?.slug}`;
            }
            //@ts-ignore
            item.slug = item.slug.replace('//', '/');
            //@ts-ignore
            item.order = index;
            return item;
        })
        .filter((breadcrumb) => breadcrumb?.name);

    return breadcrumbsElementList as BreadcrumbsItem[];
}

export type BreadcrumbsItem = {
    name: string;
    slug: string;
    order: number;
};

export type BreadcrumbsProps = {
    list: BreadcrumbsItem[];
};

export default function breadcrumbs({ list }: BreadcrumbsProps) {
    return (
        <>
            <StructuredData
                id="page-breadcrumbs"
                type={StructuredData.type.BreadcrumbList}
                data={{
                    url: `${baseUrl}${list[list.length - 1].slug}`,
                    items: list
                        /**
                         * @TODO - remove this filter
                         */
                        .filter(({ name }) => name !== 'Uncategorized')
                        .map((listItem) => {
                            return {
                                name: listItem.name,
                                item: `${baseUrl}${listItem.slug}`,
                                order: listItem.order,
                            };
                        }),
                }}
            />
            <ol className="breadcrumbs" id="breadcrumbs">
                {list
                    .filter((breadcrumbItem) => breadcrumbItem)
                    /**
                     * @TODO - remove this filter
                     */
                    .filter(({ name }) => name !== 'Uncategorized')
                    .sort((prev, next) => prev.order - next.order)
                    .map((breadcrumb) => (
                        <li className="breadcrumbs__item" key={breadcrumb?.slug}>
                            {breadcrumb.order !== 0 ? (
                                <div className="breadcrumbs__icon">
                                    <Icon
                                        size="stretch"
                                        isInline={true}
                                        name={Icon.Name.ChevronRight}
                                    />
                                </div>
                            ) : null}
                            <Link
                                className="breadcrumbs__url"
                                href={breadcrumb.slug}
                                title={breadcrumb.name}
                            >
                                <P1 inline={true} color="grey">
                                    {breadcrumb.name}
                                </P1>
                            </Link>
                        </li>
                    ))}
            </ol>
        </>
    );
}
