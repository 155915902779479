import { FormattedText } from 'components/typography';
import * as React from 'react';
import { BlockProps } from '../index.types';

export function Paragraph({ block: { innerHTML } }: BlockProps) {
    // @TODO: confirm that the issue with hydration is due to innher html - perhaps we need to remove external wrapper?
    return innerHTML ? (
        <div className="paragraph">
            <FormattedText>
                <p className="paragraph__inner" dangerouslySetInnerHTML={{ __html: innerHTML }} />
            </FormattedText>
        </div>
    ) : null;
}
