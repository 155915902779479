import * as React from 'react';
import { StructuredData } from 'components/structured-data/structured-data';
import { baseUrl } from 'lib/base-url';
import { updateImagePaths } from 'lib/updateImagePaths';

type StructuredDataElementPageProps = {
    url: string;
    category: string;
    title: string;
    openImageUrl: string;
    opengraphPublishedTime: string;
    opengraphModifiedTime: string;
};

export function StructuredDataElementPage({
    url,
    category,
    title,
    openImageUrl,
    opengraphPublishedTime,
    opengraphModifiedTime,
}: StructuredDataElementPageProps) {
    return (
        <StructuredData
            id={'article-id'}
            type={StructuredData.type.Article}
            data={{
                absUrl: baseUrl,
                url,
                category,
                title,
                imageUrl: (openImageUrl && updateImagePaths(openImageUrl)) || '',
                datePublished: opengraphPublishedTime || '',
                dateModified: opengraphModifiedTime || '',
            }}
        />
    );
}
